import { createJSONFetcher } from "../../fetchers/jsonFetcher";
import { CountryList, countryListSchema } from "./CountryList";
import qs from "query-string";
import { settings } from "../../Settings";
import { Argo, argoSchema } from "./Argo";
import { Country } from "./Country";
import { Station, stationSchema } from "./Station";
import { Variable } from "./Variable";
import { SeaAreas, seaAreasSchema } from "./SeaAreas";
import * as yup from "yup";

// XXX yup apparently has a bug causing schemas to not match their types. It occurs for fields with type
// `string[] | null`. I have tried everything to solve it, but cant', so I'm going around it instead.
// By not providing the type to `createJSONFetcher`, TS automatically infers some type, removing the error from
// the fetcher. The error is instead moved to the return, as the types there no longer match. The return is therefore
// cast to unknown and then to the correct type, as a hack to avoid the problem.
// The solution works, as the data is validated at runtime, not compile time. However, be extra careful when
// manually inspecting this code!

const BASE_URL = settings.NORARGO_BACKEND_API_URL;

/** Options for the getArgoList endpoint */
interface GetArgoListParams {
	/** List of countries to fetch argos for. If not provided, it will fetch for all of them */
	countries?: string[];
}

/**
 * Fetches a list of known Argos
 *
 * @param options Options for the query
 *
 * @returns A promise resolving to a list of argos
 *
 * @throws If there is a network error, a JSON parse error, or a data validation error
 */
const getArgoList = (options: GetArgoListParams = {}): Promise<Argo[]> => {
	// XXX See note near top of file
	const schema = yup.array(argoSchema.required()).required();
	const argoListFetcher = createJSONFetcher(schema);

	return argoListFetcher(`${BASE_URL}/get_argo_list?${qs.stringify(options)}`) as unknown as Promise<Argo[]>;
};

/**
 * Fetches the list of countries with argos
 *
 * @returns A list of names of countries with argos
 *
 * @throws If there is a network error, a JSON parse error, or a data validation error
 */
const getCountries = (): Promise<Country[]> => {
	const countryListFetcher = createJSONFetcher<CountryList>(countryListSchema.required());

	return countryListFetcher(`${BASE_URL}/get_countries`);
};

interface GetStationsOptions {
	wmo: Argo["wmo"];
	after?: Date;
	before?: Date;
	adjusted?: boolean;
	withVariables: boolean;
}
/**
 * List of stations belonging to an argo, without the data from the station
 *
 * @param options Options for the query
 * @param options.wmo The argo's unique identifier
 * @param options.after Timestamp (inclusive) to find stations after
 * @param options.before Timestamp (exclusive) to find stations before
 *
 * @returns List of stations belonging to the argo
 *
 * @throws If there is a network error, a JSON parse error, or a data validation error
 */
function getStations(
	options: GetStationsOptions & { withVariables: false }
): Promise<(Station & { variables: null })[]>;
function getStations(
	options: GetStationsOptions & { withVariables: true }
): Promise<(Station & { variables: Variable[] })[]>;
function getStations(options: GetStationsOptions): Promise<Station[]>;
async function getStations({ wmo, after, before, adjusted, withVariables }: GetStationsOptions): Promise<Station[]> {
	// XXX See note near top of file
	const schema = yup.array(stationSchema.required()).required();
	const countryListFetcher = createJSONFetcher(schema);

	const queryParams = qs.stringify({
		float_id: wmo,
		after: after?.toISOString(),
		before: before?.toISOString(),
		get_adjusted: adjusted ?? false,
		create_variables: withVariables ?? true
	});

	return countryListFetcher(`${BASE_URL}/get_stations?${queryParams}`) as unknown as Promise<
		(Station & { variables: null })[]
	>;
}

/**
 * One station belonging to an argo, with data from the station
 *
 * @param options Options for the query
 * @param options.wmo The argo's unique identifier
 * @param options.stationNumber The number of the station to get
 * @param [options.withVariables] Whether or not to include variables in the response. Defaults to true
 *
 * @returns The desired station, if it exists
 *
 * @throws If there is a network error, a JSON parse error, or a data validation error
 */
const getStation = async (options: {
	wmo: Argo["wmo"];
	stationNumber: number;
	adjusted: boolean;
}): Promise<(Station & { variables: Variable[] }) | null> => {
	// XXX See note near top of file
	const stationFetcher = createJSONFetcher(stationSchema.nullable() as typeof stationSchema);

	const queryParams = qs.stringify({
		float_id: options.wmo,
		station_id: options.stationNumber,
		get_adjusted: options.adjusted
	});

	try {
		return (await stationFetcher(`${BASE_URL}/get_station?${queryParams}`)) as unknown as Promise<
			(Station & { variables: Variable[] }) | null
		>;
	} catch (err) {
		// TODO Null only in case of 404, else rethrow
		console.error(err);
		return null;
	}
};
/**
 * Get an object with information about sea-areas.
 * @returns Sea-areas-object.
 */
const getSeaAreas = (): Promise<SeaAreas> => {
	const seaAreaFetcher = createJSONFetcher<SeaAreas>(seaAreasSchema.required());

	return seaAreaFetcher(`${BASE_URL}/get_sea_areas`);
};

export { getArgoList, getCountries, getStations, getStation, getSeaAreas };
