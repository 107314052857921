import { StrictMode } from "react";
import Router from "./components/application/Router";
import "i18next";

const App = (): JSX.Element => (
	<StrictMode>
		<Router />
	</StrictMode>
);

export default App;
