import { t } from "i18next";
import styles from "./Infobox.module.scss";

type InfoboxProps = {
	show: boolean;
	onClose: () => void;
};

const Infoxbox = ({ show, onClose }: InfoboxProps): JSX.Element => {
	const types = ["BGC", "BIO", "CORE", "DEEP"];
	const generateContent = (type: string): JSX.Element => (
		<>
			<h6>
				{type} <img src={`/${type}.png`} />
			</h6>
			<span> {t(`frontpage:sidebar:infobox:${type.toLowerCase()}`)}</span>
		</>
	);

	return (
		<div
			className={styles["infobox-modal-wrapper"]}
			style={{
				display: show ? "" : "none"
			}}
		>
			<div className={styles["infobox-modal-header"]}>
				Info:
				<span style={{ fontWeight: "bold" }} onClick={() => onClose()}>
					X
				</span>
			</div>
			<div className={styles["infobox-modal-content"]}>{types.map(generateContent)}</div>
		</div>
	);
};

export { Infoxbox };
