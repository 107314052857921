import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="container">
			<div className="row">
				<div className="col-12">
					<p>{t("application:pageNotFound")}</p>
					<Link to="/">{t("application:toFrontpage")}</Link>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
export { NotFound };
