import { createJSONFetcher } from "../../fetchers/jsonFetcher";
import qs from "query-string";
import { settings } from "../../Settings";
import * as yup from "yup";

const BASE_URL = settings.CLIMATOLOGY_URL;

/** Options for the getMeanStDev endpoint */
interface GetClimatologyParams {
	latitude: number;
	longitude: number;
	date: Date;
	variableName: string;
}

export type ClimatologyMeanStDev = {
	depth: number;
	mean: number;
	standardDeviation?: number | string;
};

export type ClimatologyResponse = {
	variableName: string;
	month: string;
	latitude: number;
	longitude: number;
	dataList: ClimatologyMeanStDev[];
};

const climatologySchema = yup
	.object({
		variableName: yup.string().required(),
		dataList: yup
			.array(
				yup
					.object({
						depth: yup.number().required(),
						mean: yup.number().nullable(),
						standardDeviation: yup.mixed().nullable()
					})
					.defined()
			)
			.defined()
	})
	.defined();

const getClimatology = (options: GetClimatologyParams): Promise<ClimatologyResponse> => {
	const optionsStr = {
		latitude: options.latitude.toString(),
		longitude: options.longitude.toString(),
		date: options.date.toISOString(), // QS does not support Date objects
		variableName: options.variableName
	};

	const argoListFetcher = createJSONFetcher(climatologySchema);

	return argoListFetcher(
		`${BASE_URL}/profile?${qs.stringify(optionsStr)}`
	) as unknown as Promise<ClimatologyResponse>;
};

export { getClimatology };
