import { Argo } from "../../models/Argo/Argo";
import { Station } from "../../models/Argo/Station";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface ChartMetadata {
	argo: Argo;
	stationData: Station;
	profileDateStringified: string;
}

const ChartMetadata = ({ argo, stationData, profileDateStringified }: ChartMetadata): JSX.Element => {
	const { t } = useTranslation();

	const listData: { title: string; data: JSX.Element | string }[] = [
		{ title: t("frontpage:chart:metadata:programs"), data: argo.program },
		{ title: t("frontpage:chart:metadata:country"), data: argo.country },
		{
			title: t("frontpage:chart:metadata:wmoCode"),
			data: (
				<a
					href={"https://fleetmonitoring.euro-argo.eu/float/" + stationData.wmo}
					target="_blank"
					rel="noreferrer noopener"
				>
					{stationData.wmo}
				</a>
			)
		},
		{ title: t("frontpage:chart:metadata:model"), data: argo.model },
		{ title: t("frontpage:chart:metadata:type"), data: argo.type },
		{
			title: t("frontpage:chart:metadata:firstCycle"),
			data: format(argo.firstStationTimestamp, "yyyy-MM-dd HH:mm")
		},
		{
			title: t("frontpage:chart:metadata:latestCycle"),
			data: format(argo.latestStationTimestamp, "yyyy-MM-dd HH:mm")
		},
		{ title: t("frontpage:chart:metadata:totalProfiles"), data: String(argo.numberOfStations) },
		{ title: t("frontpage:chart:metadata:cycleNumber"), data: String(stationData.cycle) },
		{ title: t("frontpage:chart:metadata:profileDate"), data: profileDateStringified },
		{
			title: t("frontpage:chart:metadata:profileLongitude"),
			data: String(stationData?.pos?.lon ?? t("frontpage:chart:metadata:unavailable"))
		},
		{
			title: t("frontpage:chart:metadata:profileLatitude"),
			data: String(stationData?.pos?.lat ?? t("frontpage:chart:metadata:unavailable"))
		},
		{
			title: t("frontpage:chart:metadata:data"),
			data: (
				<>
					<a href={argo.datafilesUrl} target="_blank" rel="noopener noreferrer">
						{t("frontpage:chart:metadata:downloadNetcdfFiles")}
					</a>{" "}
					<a
						href="https://argo.ucsd.edu/data/how-to-use-argo-files/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FontAwesomeIcon icon={faInfoCircle} />
					</a>
				</>
			)
		}
	];

	return (
		<dl>
			{/* The length and order will never change, so using index as key should work */}
			{listData.map(({ title, data }, i) => (
				<div key={i}>
					<dt>{title}:</dt>
					<dd>{data}</dd>
				</div>
			))}
		</dl>
	);
};

export default ChartMetadata;
export { ChartMetadata };
