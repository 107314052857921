import styles from "./Tree.module.scss";

/**
 * Creates the root of a tree that contains children provided to the Root.
 * @param children Nodes or Leafs.
 * @returns A Root of a tree, containing Nodes or Leafs.
 */
const Root = ({ children }: { children: React.ReactNode }): JSX.Element => (
	<ul className={styles["root"] + " list-unstyled"}>{children}</ul>
);

export default Root;
export { Root };
