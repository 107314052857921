import { useState } from "react";
import styles from "./Modal.module.scss";
import { DragAndMove } from "./DragAndMove";
import { WindowSize } from "../../models/WindowsSize";
import { faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ModalProps {
	title: string;
	onClose: () => void;
	children?: React.ReactNode;
	modalSize: WindowSize;
	setModalSize?: (size: WindowSize) => void;
}

const Modal = ({ title, onClose, modalSize, setModalSize, children }: ModalProps): JSX.Element => {
	// Default top and left position for the modal component
	const [mouseCoordinates, setMouseCoordinates] = useState({
		x: (window.innerWidth / 100) * 59, // This is 59 because width of modal is 40vw (this leaves 1vw right margin)
		y: (window.innerHeight / 100) * 25
	});
	const minSize: WindowSize = { width: 600, height: 600 };

	/**
	 * When user drags component, the new position should be (x + delta x) and (y + delta y).
	 * The delta values is the amount of x and y direction moved with the mouse
	 *
	 * @param {Number} deltaX The x delta value
	 * @param {Number} deltaY The y delta value
	 */
	const handleDragAndMove = (deltaX: number, deltaY: number): void => {
		// TODO: Should not be able to drag component out of vw and vh window
		setMouseCoordinates({
			x: mouseCoordinates.x + deltaX,
			y: mouseCoordinates.y + deltaY
		});
	};

	const handleResize = (deltaX: number, deltaY: number): void => {
		const s: WindowSize = {
			width: Math.max(minSize.width, modalSize.width + deltaX),
			height: Math.max(minSize.height, modalSize.height + deltaY)
		};

		setModalSize?.(s);
	};

	const { height, width } = modalSize;
	const heightWidthStyle = {
		height: `${height}px`,
		width: `${width}px`,
		maxHeight: `${height}px`,
		maxWidth: `${width}px`
	};

	return (
		<div
			className={`${styles["modal-container"]}`}
			style={{
				transform: `translate(${mouseCoordinates.x}px, ${mouseCoordinates.y}px)`,
				...heightWidthStyle
			}}
		>
			<DragAndMove onDragAndMove={handleDragAndMove}>
				<div className={styles["modal-header"]}>
					<span className={"ps-1"}>{title}</span>
					<span className={`${styles["modal-header-exit"]} pe-1`} onClick={onClose}>
						X
					</span>
				</div>
			</DragAndMove>
			<div
				className={`${styles["modal-content"]}`}
				style={{
					paddingRight: "10px",
					...heightWidthStyle
				}}
			>
				{children}
			</div>
			<div className={`${styles["modal-footer"]}`}>
				<DragAndMove onDragAndMove={handleResize}>
					<div className={styles["modal-resize"]}>
						<FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className="fa-flip-horizontal" />
					</div>
				</DragAndMove>
			</div>
		</div>
	);
};

export { Modal };
export default Modal;
