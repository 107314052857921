import * as yup from "yup";

export interface Variable {
	variableName: string;
	metadata: Record<string, unknown> & { units: string; adjustedDataExists: boolean };
	/** Tuples of (depth, value) */
	datapoints: [number, number][];
}

export const variableSchema = yup.object({
	variableName: yup.string().required(),
	metadata: yup
		.object({
			units: yup.string().required(),
			adjustedDataExists: yup.boolean().required()
		})
		.camelCase()
		.required(),
	datapoints: yup.array(yup.array(yup.number()).length(2).required())
});

// Make sure the schema actually describes the type. This line will cause compilation errors if they do not match
variableSchema as unknown as yup.Schema<Variable>;
