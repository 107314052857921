const frontpage = {
	banner: {
		bannerTitle: "NorArgo: Norwegian Argo infrastructure",
		language: "Language",
		reset: "Reset"
	},
	seaareas: {
		numberOfActiveFloats: "NUMBER OF ACTIVE FLOATS"
	},
	sidebar: {
		filterTitle: "Filter floats by date (start–end)",
		filterButton: "Filter",
		activeWithinInterval: "Floats within the interval ({{n}} days)",
		showPath: "Show float drift ({{n}} days)",
		activeFloats: "Active floats (last position)",
		floatsByCountry: "Floats by country",
		floatsByType: "Floats by type",
		floats: "Floats",
		stations: "Stations",
		path: "Path",
		today: "Today",
		infobox: {
			bgc: "Pressure, temperature, salinity and 6 biogeochemical variables (oxygen, chlorophyll a, suspended particles, irradiance, nitrate, pH)",
			bio: "Pressure, temperature, salinity and 1-5 biogeochemical variables listed in BGC",
			core: "Pressure, temperature, salinity. Profile depth to maximum 2000 dbar",
			deep: "Pressure, temperature, salinity and often oxygen. Maximum profile depth deeper than 2000 dbar"
		}
	},
	chart: {
		previous: "Previous",
		next: "Next",
		showAllProfiles: "Show all profiles",
		invalidStations: "Invalid response for {{numberOfInvalidStations}} stations while retrieving data",
		showClimatologyProfile: "Show closest 1°x1° climatology profile",
		showClimatologyProfileInfo: "(Exists only for TEMP and PSAL)",
		profile: "Profile at",
		depth: "Depth (dbar)",
		values: "Values",
		downloadCsv: "Download CSV",
		downloadInstructions: "Show all profiles to activate download button.",
		measuredValues: "Measured values",
		adjustedValues: "Adjusted values",
		adjustedValuesDontExistShowingMeasuredInstead:
			"Adjusted values does not exist for this variable. Showing measured values instead",
		metadata: {
			programs: "Program(s)",
			type: "Type",
			country: "Country",
			wmoCode: "WMO code",
			model: "Model",
			firstCycle: "First cycle",
			latestCycle: "Latest cycle",
			cycleNumber: "Cycle number",
			totalProfiles: "Total profiles",
			profileDate: "Profile date",
			profileLongitude: "Profile longitude",
			profileLatitude: "Profile latitude",
			data: "Data",
			downloadNetcdfFiles: "Download NetCDF files",
			unavailable: "Unavailable"
		}
	},
	map: {
		norwegian: "Norwegian",
		foreign: "Foreign"
	}
} as const;

export default frontpage;
