import * as yup from "yup";
import { Variable, variableSchema } from "./Variable";

export interface Station {
	stationNumber: number;
	stationData: Record<string, unknown> & { latitude: number; longitude: number; cycleNumber: number; time: Date };
	variables: Variable[] | null;
}

export const stationSchema = yup.object({
	stationNumber: yup.number().required(),
	stationData: yup
		.object({
			cycleNumber: yup.number().required(),
			time: yup.date().required(),
			// XXX It is possible for lat/lon to be 99999. This happens when the argo didn't manage
			// to get its location at the surface
			latitude: yup.number().required(),
			longitude: yup.number().required()
		})
		.camelCase()
		.required(),
	variables: yup.array(variableSchema.required()).nullable().defined()
});
