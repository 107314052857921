import TileWMS from "ol/source/TileWMS";
import { useMemo } from "react";
import TileLayer from "./TileLayer";

interface GebcoWMSLayerProps {
	zIndex: number;
}

const GebcoWMSLayer = ({ zIndex }: GebcoWMSLayerProps): JSX.Element => {
	// Create the source
	const source = useMemo(
		() =>
			new TileWMS({
				url: "https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?",
				params: {
					LAYERS: "gebco_latest",
					format: "image/png",
					transparent: false,
					isBaseLayer: true
				}
			}),
		[]
	);

	// Render a tile layer with this source
	return <TileLayer zIndex={zIndex} source={source} />;
};

export default GebcoWMSLayer;
export { GebcoWMSLayer };
