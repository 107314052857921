import * as yup from "yup";
import { Country, countrySchema } from "./Country";

/** Type of the response to the getCountries backend endpoint */
export type CountryList = Country[];

/** Validation schema for the CountryList type */
export const countryListSchema = yup.array(countrySchema.required());

// Make sure the schema actually describes the type. This line will cause compilation errors if they do not match
countryListSchema as yup.Schema<CountryList>;
