import { parse, format } from "date-fns";
import styles from "../pages/Frontpage.module.scss";

interface dateInputProps {
	value: Date;
	onChange: (date: Date) => void;
	min?: Date;
	max?: Date;
}
/**
 * Component for taking date input.
 * @param value: Text to be displayed in the input field.
 * @param onChange: Function executed when input changes.
 * @param validDate: If the date added in the input filed is valid or not.
 * @returns A Dateinput component.
 */
const DateInput = ({ value, onChange, min, max }: dateInputProps): JSX.Element => {
	// Helperfunctions for converting date to string and opposite. Both uses same date-format.
	const dateFormat = "yyyy-MM-dd";
	const date2string = (date: Date) => {
		try {
			return format(date, dateFormat);
		} catch {
			return "";
		}
	};
	// {/* className={styles["filter-and-date-input"]} */}
	const string2date = (str: string) => parse(str, dateFormat, new Date());
	return (
		<input
			className={`${styles["input-font-size"]} form-control`}
			type="date"
			value={date2string(value)}
			onChange={e => onChange(string2date(e.target.value))}
			min={min ? date2string(min) : date2string(new Date(2000, 1, 1))}
			max={max ? date2string(max) : date2string(new Date())}
		/>
	);
};

export default DateInput;
export { DateInput as DateInput };
