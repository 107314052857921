import styles from "./Tree.module.scss";
import React, { useState, Children } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight as Right, faChevronCircleDown as Down } from "@fortawesome/free-solid-svg-icons";

interface TreeItemProps {
	/** The text to show on the item. */
	label: string | JSX.Element;
	/** A list of TreeItems to show when this item is expanded. */
	children?: React.ReactNode[];
	/** The level the item is on in the built tree-hierarchy */
	level: number;
	/** Indicates if the checkbox is checked or not*/
	isChecked?: boolean;
	/** Function to run when the checkbox is checked/unchecked */
	onChange?: (checked: boolean) => void;
	/** Function to run when the node is collapsed */
	onCollapse?: () => void;
}

/**
 * Creates a TreeNode with a label and potential child elements.
 * @param label Name of the TreeNode.
 * @param children Children of the TreeNode, an array of TreeNode and/or TreeLeaf elements.
 * @param level Hierarchy-level the item is at, in the constructed tree.
 * @returns A TreeNode with its potential children nodes.
 */
const Node = ({ label, children, level, isChecked, onChange, onCollapse }: TreeItemProps): JSX.Element => {
	const [isExpanded, setIsExpanded] = useState(false);
	const isExpandedIcon = (isExpanded: boolean) => (isExpanded ? Down : Right);
	const className = `tree-level-${level}`;

	const toggleCollapse = () =>
		setIsExpanded(isExpanded => {
			const newIsExpanded = !isExpanded;

			if (!newIsExpanded && onCollapse !== undefined) {
				onCollapse();
			}

			return newIsExpanded;
		});

	return (
		<li className={`${styles["node"]} ${styles[className]}`}>
			<label onClick={toggleCollapse} className={styles["left-node-label"]}>
				<FontAwesomeIcon icon={isExpandedIcon(isExpanded)} />
			</label>
			{onChange ? (
				<label className={styles["right-node-label"]}>
					<input type="checkbox" checked={isChecked} onChange={e => onChange(e.target.checked)} />
				</label>
			) : null}
			<label onClick={toggleCollapse}>{label}</label>
			{isExpanded && Children.count(children) > 0 ? <ul className={"list-unstyled"}>{children}</ul> : null}
		</li>
	);
};

export default Node;
export { Node };
