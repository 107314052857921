import * as yup from "yup";

export interface SeaAreas {
	arcticOcean: number;
	boundaryCurrent: number;
	barentsSea: number;
	greenlandSea: number;
	norwegianBasin: number;
	lofotenBasin: number;
	icelandSea: number;
}

export const seaAreasSchema = yup
	.object({
		arcticOcean: yup.number().required(),
		boundaryCurrent: yup.number().required(),
		barentsSea: yup.number().required(),
		greenlandSea: yup.number().required(),
		norwegianBasin: yup.number().required(),
		lofotenBasin: yup.number().required(),
		icelandSea: yup.number().required()
	})
	.camelCase();

seaAreasSchema as yup.Schema<SeaAreas>;
