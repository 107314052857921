import { useTranslation } from "react-i18next";
import Root from "./Root";
import Node from "./Node";
import Leaf from "./Leaf";
import { Argo } from "../../models/Argo/Argo";
import * as dateFns from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const UNDEFINED_COUNTRY_DEFAULT = "country not set";

interface TreeLeaf {
	label: string;
	isChecked: boolean;
	onChange: (checked: boolean) => void;
	iconUrl?: string;
	tooltipLabel?: string;
}

interface TreeNode {
	label: string | JSX.Element;
	children: Tree[];
	onCollapse?: () => void;
	isChecked?: boolean;
	onChange?: (checked: boolean) => void;
}

type Tree = TreeNode | TreeLeaf;

/**
 * Checks if a Tree-element is a TreeNode.
 * @param tree a TreeNode or a TreeLeaf element.
 * @returns true if the Tree-element is a TreeNode.
 */
const isTreeNode = (tree: Tree): tree is TreeNode =>
	// XXX The casting is a hack to make TypeScript accept that children may or may not exist.
	Array.isArray((tree as unknown as { children?: TreeNode[] }).children);

/**
 * Renders a navigation tree based on a TreeLeaf or TreeNode. Children of TreeNodes are also rendered.
 *
 * @param tree The root node of the tree or the node/leaf to be rendered.
 *
 * @returns a TreeLeaf or a TreeNode.
 */
const renderTree = (tree: Tree, parentPath: string[] = []): JSX.Element => {
	const path = [...parentPath, tree.label];
	const key = path.join(".");
	if (isTreeNode(tree)) {
		return (
			<Node
				label={tree.label}
				key={key}
				level={path.length}
				isChecked={tree.isChecked}
				onChange={tree.onChange}
				onCollapse={tree.onCollapse}
			>
				{tree.children.map(node => renderTree(node, path as string[]))}
			</Node>
		);
	} else {
		return (
			<Leaf
				key={key}
				label={tree.label}
				level={path.length}
				isChecked={tree.isChecked}
				onChange={tree.onChange}
				iconUrl={tree.iconUrl}
				tooltipLabel={tree.tooltipLabel}
			></Leaf>
		);
	}
};

/**
 * Finds the unique countries a list of argos belongs to
 *
 * @param argos List of argos to find countries for
 *
 * @returns an array of unique countries in the argo list
 */
const getUniqueCountries = (argos: Argo[]) => {
	const countries = argos.map(argo => argo.country || UNDEFINED_COUNTRY_DEFAULT);
	return [...new Set(countries)];
};

interface TreeBuilderProps {
	argos: Argo[];
	getShowPath: (argo: Argo) => boolean;
	getShowStations: (argo: Argo) => boolean;
	setShowPath: (argo: Argo, showPath: boolean) => void;
	setShowStations: (argo: Argo, showStations: boolean) => void;
	interval: dateFns.Interval;
	showWithinInterval: boolean;
	setShowWithinInterval: (showWithinInterval: boolean) => void;
	showPathForArgos: boolean;
	setShowPathForArgos: (showWithinInterval: boolean) => void;
	selectedCountries: Set<string>;
	setCountrySelected: (country: string, checked: boolean) => void;
	selectedArgoTypes: Set<string>;
	setArgoTypeSelected: (country: string, checked: boolean) => void;
	setShowInfoBox: (showInfoBox: boolean) => void;
}

/**
 * Builds a tree from a (dummy) dataset. The rootnodes are hardcoded in this function.
 * The children of the root are TreeNodes with country-names as labels. The country-TreeNode-children are floats with two children (LeafNodes) each.
 * @param argos object containing data that will be used to generate the tree.
 * @returns a representation of the tree that will be rendered.
 */
const TreeBuilder = ({
	argos,
	getShowPath,
	getShowStations,
	setShowPath,
	setShowStations,
	interval,
	showWithinInterval,
	setShowWithinInterval,
	showPathForArgos,
	setShowPathForArgos,
	selectedCountries,
	setCountrySelected,
	selectedArgoTypes,
	setArgoTypeSelected,
	setShowInfoBox
}: TreeBuilderProps): JSX.Element => {
	const { t } = useTranslation();
	const stationsTranslate = t("frontpage:sidebar:stations");
	const pathTranslate = t("frontpage:sidebar:path");

	const countries = getUniqueCountries(argos);

	const floatsByCountryRoot: TreeNode = {
		label: `${t("frontpage:sidebar:floatsByCountry")}`,
		children: [...countries].sort().map(country => {
			const countryArgos = argos
				// Pick only argos from this country.
				.filter(argo => argo.country === country)
				// Turn them into TreeNodes with two TreeLeaf children.
				.map(argo => ({
					label: argo.wmo,
					argoWmo: argo.wmo,
					onCollapse: () => {
						setShowPath(argo, false);
						setShowStations(argo, false);
					},
					children: [
						{
							label: pathTranslate,
							isChecked: getShowPath(argo),
							onChange: (checked: boolean) => setShowPath(argo, checked)
						},
						{
							label: stationsTranslate,
							isChecked: getShowStations(argo),
							onChange: (checked: boolean) => setShowStations(argo, checked)
						}
					]
				}));

			return {
				label: `${country}`,
				children: countryArgos,
				isChecked: selectedCountries.has(country),
				onChange: (checked: boolean) => setCountrySelected(country, checked)
			};
		})
	};

	const allFloatsRoot: TreeNode = {
		label: `${t("frontpage:sidebar:floats")}`,
		children: argos.map(argo => ({
			label: `${argo.wmo} – ${argo.country || UNDEFINED_COUNTRY_DEFAULT}`,
			argoWmo: argo.wmo,
			onCollapse: () => {
				setShowPath(argo, false);
				setShowStations(argo, false);
			},
			children: [
				{
					label: pathTranslate,
					isChecked: getShowPath(argo),
					onChange: (checked: boolean) => setShowPath(argo, checked)
				},
				{
					label: stationsTranslate,
					isChecked: getShowStations(argo),
					onChange: (checked: boolean) => setShowStations(argo, checked)
				}
			]
		}))
	};

	/**
	 * Finds the unique types of argos from a list of argos.
	 *
	 * @param argos List of argos to find tyopes from.
	 *
	 * @returns an array of unique types in the argo list.
	 */
	const getArgoTypes = (argos: Argo[]) => {
		const argoTypes = argos.map(argo => argo.type);
		return [...new Set(argoTypes)];
	};

	const argoTypes = getArgoTypes(argos);

	const openInfoBox = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.stopPropagation();
		setShowInfoBox(true);
	};

	const floatsByType: TreeNode = {
		label: (
			<>
				{t("frontpage:sidebar:floatsByType")} <FontAwesomeIcon icon={faInfoCircle} onClick={openInfoBox} />
			</>
		),
		children: [...argoTypes].sort().map(argotype => ({
			label: `${argotype}`,
			isChecked: selectedArgoTypes.has(argotype),
			onChange: (checked: boolean) => setArgoTypeSelected(argotype, checked),
			iconUrl: "/" + argotype + ".png"
		}))
	};

	const withinInterval: TreeLeaf = {
		label: t("frontpage:sidebar:activeWithinInterval", {
			n: dateFns.differenceInDays(interval.end, interval.start)
		}),
		isChecked: showWithinInterval,
		onChange: setShowWithinInterval
	};
	const showPathConfig: TreeLeaf = {
		label: t("frontpage:sidebar:showPath", {
			n: dateFns.differenceInDays(interval.end, interval.start)
		}),
		isChecked: showPathForArgos,
		onChange: setShowPathForArgos
	};

	//All nodes/leafs that will be generated on root level.
	const roots = [withinInterval, showPathConfig, floatsByCountryRoot, floatsByType, allFloatsRoot];

	return <Root>{roots.map(child => renderTree(child))}</Root>;
};

export default TreeBuilder;
export { TreeBuilder };
