import { Argo } from "./Argo/Argo";
import { Station } from "./Argo/Station";
import { Station as BStation } from "./Backend/Station";
import { Variable } from "./Argo/Variable";
import { Variable as BVariable } from "./Backend/Variable";
import * as Backend from "./Backend";
import { convertArgo, convertStation, convertVariable } from "./Backend/toInternalFormat";
import { Interval } from "date-fns";

type WMO = Argo["wmo"];
type StationNumber = Station["number"];
interface VariableObj {
	measured?: Promise<Variable[]>;
	adjusted?: Promise<Variable[]>;
}

/** Handles fetching and caching of argo data */
class ArgoManager {
	/** The list of cached argos */
	private argoMapP: Promise<Map<WMO, Argo>> = Promise.resolve(new Map<WMO, Argo>());
	/** Map between argo WMOs and maps between station numbers and variables */
	private variablesMap: Map<WMO, Map<StationNumber, VariableObj>> = new Map();

	constructor() {
		// Clearing the cache basically resets and reinitializes the manager
		this.clearCache();
	}

	/** Clears all cached data in the manager */
	public clearCache(): void {
		// Update the argo list
		this.argoMapP = ArgoManager.fetchArgoMap();

		// Reset the other caches
		this.variablesMap = new Map();
	}

	/** Fetches a map between WMOs and argos for all known argos */
	private static async fetchArgoMap(): Promise<Map<WMO, Argo>> {
		// Fetch the current argo list from backend
		const fetchedArgos = await Backend.getArgoList();

		// Convert them to the internal format and make a map of them
		const argoTuples = fetchedArgos.map(convertArgo).map(argo => [argo.wmo, argo] as const);
		return new Map(argoTuples);
	}

	/**
	 * Fetches all stations belonging to an argo from the backend
	 *
	 * @param argo The argo to fetch stations for
	 *
	 * @returns List of the argo's stations
	 *
	 * @throws On network errors, parse errors, or if the argo does not exist
	 */
	private static async fetchStationsFromBackend(options: {
		argo: Argo;
		interval: Interval;
		withVariables: boolean;
	}): Promise<Station[]> {
		// Fetch and convert the stations
		const bStations = await Backend.getStations({
			wmo: options.argo.wmo,
			after: new Date(options.interval.start),
			before: new Date(options.interval.end),
			withVariables: options.withVariables
		});
		return bStations.map(bStation => convertStation(options.argo, bStation));
	}

	/**
	 * Fetches variables belonging to stations from backend
	 *
	 * @param stations The stations to get variables for
	 *
	 * @returns Map between the stations and a promise resolving to its variables
	 */
	private static fetchVariablesFromBackend(
		stations: Station[],
		{ adjusted }: { adjusted: boolean }
	): Map<Station, Promise<Variable[]>> {
		// Cache of requested stations per argo
		const cache = new Map<Argo["wmo"], Promise<(BStation & { variables: BVariable[] })[]>>();

		// Get a list of all stations in the map
		const stationVariablesTuples = stations.map(station => {
			// Get the newly fetched stations for this station's argo from the cache
			let bStationsP = cache.get(station.wmo);
			if (bStationsP === undefined) {
				// Not in the cache. Fetch them
				bStationsP = Backend.getStations({
					wmo: station.wmo,
					withVariables: true,
					adjusted
				});

				// Update the cache
				cache.set(station.wmo, bStationsP);
			}

			// Extract the variables from the newly fetched data
			const variablesP = bStationsP
				.then(bStations => {
					// Find the newly fetched station corresponding to the current station being processed
					const bStation = bStations.find(({ stationNumber }) => station.number === stationNumber);
					if (bStation === undefined) {
						// Should never happen, but does for some files that are too big and thus not caching all stations we think
						// throw new Error("Unknown station");
						// Show error in frontend instead of crashing
						console.error("Invalid station: " + station?.number);
						return {
							stationNumber: station.number,
							variables: null
						};
					}

					return bStation;
				})
				.then(
					bStation =>
						// Extract the variables from the newly fetched data
						bStation.variables?.map(variable => convertVariable(station, variable)) || []
				);

			return [station, variablesP] as const;
		});

		return new Map(stationVariablesTuples);
	}

	/**
	 * Fetches variables for one station
	 *
	 * @param station The station to fetch variables for
	 *
	 * @returns Promise resolving to a list of the stations variables, or promise resolving to null if the station does not exist
	 */
	private static async fetchVariablesForStation(
		station: Station,
		{ adjusted }: { adjusted: boolean }
	): Promise<Variable[] | null> {
		const bStation = await Backend.getStation({ wmo: station.wmo, stationNumber: station.number, adjusted });

		if (bStation === null) {
			return null;
		}

		return bStation.variables.map(variable => convertVariable(station, variable));
	}

	/**
	 * Fetches one station belonging to an argo from the backend
	 *
	 * @param argo The argo to get the station for
	 * @param stationNumber Number of the station to fetch
	 *
	 * @returns The requested station
	 *
	 * @throws On network errors, parse errors, or if the argo does not exist
	 */
	private static async fetchStationFromBackend(options: {
		argo: Argo;
		stationNumber: StationNumber;
		adjusted: boolean;
	}): Promise<Station | null> {
		const bStation = await Backend.getStation({ ...options, wmo: options.argo.wmo });

		return bStation !== null ? convertStation(options.argo, bStation) : null;
	}

	/** Fetches all argos this manager knows about */
	public async fetchArgos(): Promise<Argo[]> {
		const argoMap = await this.argoMapP;

		return [...argoMap.values()];
	}

	/**
	 * Fetches a specific argo
	 *
	 * @param wmo Unique identifier of the argo to get
	 *
	 * @returns The requested argo, if found
	 */
	public async fetchArgo(wmo: WMO): Promise<Argo | null> {
		const argoMap = await this.argoMapP;

		return Promise.resolve(argoMap.get(wmo) ?? null);
	}

	/**
	 * Fetches all stations belonging to an argo
	 *
	 * @param wmo Unique identifier of the argo to get the stations for
	 *
	 * @returns List of the argo's stations
	 *
	 * @throws On network errors, parse errors, or if the parent argo does not exist
	 */
	/* eslint-disable class-methods-use-this */
	public fetchStations(argo: Argo, after: Date, before: Date): Promise<Station[]> {
		// Request the stations
		return ArgoManager.fetchStationsFromBackend({
			argo,
			interval: { start: after, end: before },
			withVariables: false
		});
	}
	/* eslint-enable class-methods-use-this */

	/**
	 * Fetches all stations an argo has ever had
	 *
	 * @param argo The argo to fetch stations for
	 *
	 * @returns List of all stations the argo has done
	 *
	 * @throws On network errors, parse errors, or if the parent argo does not exist
	 */
	public fetchAllStations(argo: Argo): Promise<Station[]> {
		return this.fetchStations(argo, new Date(0), new Date());
	}

	/**
	 * Fetches one station belonging to an argo
	 *
	 * @param wmo Unique identifier of the argo to get the station for
	 * @param stationNumber Number of the station to get
	 *
	 * @returns The station, if it exists
	 *
	 * @throws On network errors, parse errors, or if the parent argo does not exist
	 */
	/* eslint-disable class-methods-use-this */
	public async fetchStation(argo: Argo, stationNumber: StationNumber, adjusted: boolean): Promise<Station | null> {
		return ArgoManager.fetchStationFromBackend({ argo, stationNumber, adjusted });
	}
	/* eslint-enable class-methods-use-this */

	/**
	 * Fetches all variables for the given stations
	 *
	 * @param stations List of stations to fetch variables for
	 * @param options Options for the query
	 * @param options.adjusted Whether to fetch measured or adjusted data
	 *
	 * @returns Map between the stations in the input list and their variables
	 */
	public async fetchVariables(
		stations: Station[],
		{ adjusted }: { adjusted: boolean }
	): Promise<Map<Station, Variable[]>> {
		// Make the map which holds the results
		const resultMap = new Map<Station, Promise<Variable[]>>();

		/*************************
		 * Fetch data from cache *
		 *************************/

		const varType = adjusted ?? false ? "adjusted" : "measured";

		// Check if any of the stations already have their variables fetched
		for (const station of stations) {
			const variables = this.variablesMap.get(station.wmo)?.get(station.number)?.[varType];
			if (variables !== undefined) {
				// Variables for this station has already been fetched. Add them to the map
				resultMap.set(station, variables);
			}
		}

		/*****************************************
		 * Fetch the remaining data from backend *
		 *****************************************/

		// Get the stations which does not already have their variables fetched
		const unprocessedStations = stations.filter(station => !resultMap.has(station));

		// Fetch the data for the new stations from backend
		const newVariables = new Map<Station, Promise<Variable[]>>();

		if (unprocessedStations.length === 1) {
			// Fetching one station is faster than fetching all if there is only one
			const station = unprocessedStations[0];
			const variablesP = ArgoManager.fetchVariablesForStation(station, { adjusted }).then(variables => {
				if (variables === null) {
					throw new Error("Invalid station");
				}
				return variables;
			});
			newVariables.set(station, variablesP);
		} else {
			// Fetch them all and put them in the map
			for (const [key, value] of ArgoManager.fetchVariablesFromBackend(unprocessedStations, { adjusted })) {
				newVariables.set(key, value);
			}
		}

		for (const [station, variablesP] of newVariables) {
			// Add them to the result
			resultMap.set(station, variablesP);

			// Cache the variables
			const innerMap = this.variablesMap.get(station.wmo) ?? new Map<Station["number"], VariableObj>();
			const varObj = innerMap.get(station.number) ?? {};
			varObj[varType] = variablesP;
			innerMap.set(station.number, varObj);
			this.variablesMap.set(station.wmo, innerMap);
		}

		/**********************************
		 * Wait for everything to resolve *
		 **********************************/

		const resultTuples = await Promise.all(
			[...resultMap].map(async ([station, variablesP]) => {
				const variables = await variablesP;
				return [station, variables] as const;
			})
		);
		return new Map(resultTuples);
	}

	/**
	 * Fetches a variable belonging to a station
	 *
	 * @param wmo Unique identifier of the argo the station belongs to
	 * @param stationNumber Number of the station to get the variable for
	 * @param variableName Name of the variable to get
	 *
	 * @returns The desired variable, if it exists
	 */
	public async fetchVariable(
		station: Station,
		variableName: Variable["name"],
		{ adjusted }: { adjusted: boolean }
	): Promise<Variable | null> {
		// Get the station's variables and pick the desired one
		const variables = await this.fetchVariables([station], { adjusted });
		return variables.get(station)?.find(({ name }) => name === variableName) ?? null;
	}
}

export default ArgoManager;
export { ArgoManager };
