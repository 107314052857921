import GebcoWMSLayer from "../map/layers/GebcoWMSLayer";
import Layers from "../map/layers/Layers";
import { Map as MapContainer } from "../map/Map";
import { Argo } from "../../models/Argo/Argo";
import { Station } from "../../models/Argo/Station";
import styles from "./Map.module.scss";
import { useTranslation } from "react-i18next";
import { ArgoLayerActiveStation, ArgoLayerStationInterval } from "../map/layers/Argo/Argo";

interface MapProps {
	argos: Argo[] | null;
	stationsInInterval: Map<Argo["wmo"], Station[]>;
	getStations: (argo: Argo) => Station[] | null;
	getShowPath: (argo: Argo) => boolean;
	getShowStations: (argo: Argo) => boolean;
	focusedStation: Station | null;
	onStationClick: (station: Station) => void;
	onPointClick: (id: string) => void;
	showPathForArgos: boolean;
}

const Descriptions = (): JSX.Element => {
	const { t } = useTranslation();
	return (
		<div className={styles["float-color-description"]}>
			<div>
				<span className={`${styles["red-dot"]} ms-1`}></span>
				<span className="ms-1">{t("frontpage:map:norwegian")}</span>
			</div>
			<div>
				<span className={`${styles["yellow-dot"]} ms-1`}></span>
				<span className="ms-1">{t("frontpage:map:foreign")}</span>
			</div>
			<div>
				<span className={`${styles["green-dot"]} ms-1`}></span>
				<span className="ms-1">{t("frontpage:chart:metadata:firstCycle")}</span>
			</div>
		</div>
	);
};

const Map = ({
	argos,
	stationsInInterval,
	getStations,
	getShowPath,
	getShowStations,
	focusedStation,
	onStationClick,
	onPointClick,
	showPathForArgos
}: MapProps): JSX.Element => {
	return (
		<MapContainer
			center={{ lat: 70, lon: 5 }}
			zoom={5}
			projection={"EPSG:4326"}
			onStationClick={onStationClick}
			stationsInInterval={stationsInInterval}
			getStations={getStations}
			onPointClick={onPointClick}
		>
			<Layers>
				<GebcoWMSLayer zIndex={0} />
				<ArgoLayerStationInterval
					argos={argos}
					stationsInInterval={stationsInInterval}
					onStationClick={onStationClick}
					focusedStation={focusedStation}
					getStations={getStations}
					getShowStations={getShowStations}
					getShowPath={getShowPath}
					showPathForArgos={showPathForArgos}
				/>
				<ArgoLayerActiveStation
					argos={argos}
					stationsInInterval={stationsInInterval}
					onStationClick={onStationClick}
					focusedStation={focusedStation}
					getStations={getStations}
					getShowStations={getShowStations}
					getShowPath={getShowPath}
					showPathForArgos={showPathForArgos}
				/>

				<Descriptions />
			</Layers>
		</MapContainer>
	);
};

export default Map;
export { Map };
