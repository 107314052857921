/* eslint-disable @typescript-eslint/no-misused-promises */
import NorArgoLogo from "../assets/logo/logo.png";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import styles from "./Banner.module.scss";
const FrontpageBanner = (): JSX.Element => {
	const { t, i18n } = useTranslation();
	const languages = [
		{ code: "nb", label: "Norsk" },
		{ code: "en", label: "English" }
	];
	return (
		<>
			<div className={`${styles["language-and-reset-elements-positioning"]} ms-3`}>
				<ul className="navbar-nav ms-auto mb-2 mb-lg-0 mt-1">
					<li className="nav-item dropdown">
						<a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">
							<FontAwesomeIcon icon={faLanguage} /> {t("frontpage:banner:language")}
						</a>
						<ul className="dropdown-menu">
							{languages.map(({ code, label }) => (
								<li key={code}>
									<a className="dropdown-item" href="#" onClick={() => i18n.changeLanguage(code)}>
										{label}
									</a>
								</li>
							))}
						</ul>
					</li>
				</ul>
				<button className="ms-3 btn btn-warning btn-sm" onClick={() => window.location.reload()}>
					{t("frontpage:banner:reset")}
				</button>
			</div>
			<div className={styles["logo-header"]}>
				<h1>{t("frontpage:banner:bannerTitle")}</h1>
				<a href="https://norargo.hi.no/">
					<img src={NorArgoLogo} alt="logo"></img>
				</a>
			</div>
		</>
	);
};

export default FrontpageBanner;
export { FrontpageBanner };
