import React, { useState, useEffect, useCallback } from "react";

interface DragMoveProps {
	onDragAndMove: (x: number, y: number) => void;
	children?: React.ReactNode;
}

const DragAndMove = ({ onDragAndMove, children }: DragMoveProps): JSX.Element | null => {
	// If user is dragging, update state
	const [isDragging, setIsDragging] = useState(false);

	// When user starts to drag, store x and y coordinates in state
	const [{ startClickX, startClickY }, setStartClick] = React.useState({
		startClickX: 0,
		startClickY: 0
	});

	// Wihle user drags the mouse, store delta x and y coordinates in state
	const [{ deltaX, deltaY }, setDelta] = React.useState({
		deltaX: 0,
		deltaY: 0
	});

	// While user press and holds the component around, keep updating the delta X and delta Y variable in state
	const handlePointerMove = useCallback(
		(e: DragEvent) => {
			const deltaObj = {
				deltaX: e.clientX - startClickX,
				deltaY: e.clientY - startClickY
			};
			setDelta(deltaObj);
		},
		[setDelta, startClickX, startClickY]
	);

	// Need to add dragover event to the window. If this is not done, clientX and clientY values of the event (current mouse x and y coordinates while holding) will always be 0 for firefox.
	useEffect(() => {
		if (isDragging) {
			window.addEventListener("dragover", handlePointerMove);
		}
		return () => {
			if (isDragging) {
				window.removeEventListener("dragover", handlePointerMove);
			}
		};
	}, [isDragging, handlePointerMove]);

	// When user press and holds the component, store x and y mouse coordinates of the mouse in state
	const handlePointerDown = (e: React.DragEvent<HTMLDivElement>) => {
		setIsDragging(true);
		setStartClick({ startClickX: e.clientX, startClickY: e.clientY });
	};

	// When user releases the mouse, update translate values
	const handlePointerUp = () => {
		setIsDragging(false);
		onDragAndMove(deltaX, deltaY);
	};
	return (
		<div onDragStart={handlePointerDown} onDragEnd={handlePointerUp} draggable={true}>
			{children}
		</div>
	);
};

export default DragAndMove;
export { DragAndMove };
