import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Frontpage } from "../pages/Frontpage";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import NotFound from "./NotFound";

const Router = (): JSX.Element => (
	<BrowserRouter>
		<I18nextProvider i18n={i18n}>
			<Routes>
				<Route path="/" element={<Frontpage />} />
				{/* Catch-all if no routes are matched */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</I18nextProvider>
	</BrowserRouter>
);

export default Router;
export { Router };
