const frontpage = {
	banner: {
		bannerTitle: "NorArgo: Norsk Argo infrastruktur",
		language: "Språk",
		reset: "Tilbakestill"
	},
	seaareas: {
		numberOfActiveFloats: "ANTALL AKTIVE BØYER"
	},
	sidebar: {
		filterTitle: "Filtrer bøyer basert på dato (start–slutt)",
		filterButton: "Filtrer",
		activeWithinInterval: "Bøyer innenfor intervallet ({{n}} dager)",
		showPath: "Vis bøyebevegelser ({{n}} dager)",
		activeFloats: "Aktive bøyer (siste posisjon)",
		floatsByCountry: "Bøyer sortert etter land",
		floatsByType: "Bøyer sortert etter type",
		floats: "Bøyer",
		stations: "Stasjoner",
		path: "Drift",
		today: "Today",
		infobox: {
			bgc: "Trykk, temperatur, saltholdighet og 6 biogeokjemiske variabler (oksygen, klorofyll a, partikler, lys, nitrat, pH)",
			bio: "Trykk, temperatur, saltholdighet og 1-5 biogeokjemiske variabler som oppført i BGC",
			core: "Trykk, temperatur og saltholdighet. Profildybde til maksimalt 2000 dbar",
			deep: "Trykk, temperatur, saltholdighet og ofte oksygen. Maksimal profildybde dypere enn 2000 dbar"
		}
	},
	chart: {
		previous: "Forrige",
		next: "Neste",
		showAllProfiles: "Vis alle profiler",
		invalidStations: "Ugyldig data for {{numberOfInvalidStations}} stasjoner",
		showClimatologyProfile: "Vis nærmeste 1°x1° klimatologiprofil",
		showClimatologyProfileInfo: "(Finnes kun for TEMP og PSAL)",
		profile: "Profil fra",
		depth: "Dybde (dbar)",
		values: "Verdier",
		downloadCsv: "Last ned CSV",
		downloadInstructions: "Vis alle profiler for å aktivere nedlastningsknappen.",
		measuredValues: "Målte verdier",
		adjustedValues: "Justerte verdier",
		adjustedValuesDontExistShowingMeasuredInstead:
			"Justerte verdier finnes ikke for denne variabelen. Viser målte istedet",
		metadata: {
			programs: "Program(mer)",
			type: "Type",
			country: "Land",
			wmoCode: "WMO kode",
			model: "Modell",
			firstCycle: "Første syklus",
			latestCycle: "Seneste syklus",
			cycleNumber: "Syklus nummer",
			totalProfiles: "Totalt antall profiler",
			profileDate: "Profil dato",
			profileLongitude: "Profil lengdegrad",
			profileLatitude: "Profil breddegrad",
			data: "Data",
			downloadNetcdfFiles: "Last ned NetCDF-filer",
			unavailable: "Utilgjengelig"
		}
	},
	map: {
		norwegian: "Norske",
		foreign: "Utenlandske"
	}
} as const;

export default frontpage;
