import * as yup from "yup";

/** An argo float, raw from the backend */
export interface Argo {
	/** A unique identifier for the float */
	wmo: string;
	/** The float's metadata */
	globalMetadata: Record<string, unknown> & {
		country: string;
		program: string;
		argoType: string;
		argoModel: string;
		lastStationTime: Date;
		firstStationTime: Date;
		latestCycle: number;
		numberOfStations: number;
		datafilesUrl: string;
	};
}

export const argoSchema = yup.object({
	wmo: yup.string().required(),
	globalMetadata: yup
		.object({
			country: yup.string().required(),
			program: yup.string().required(),
			argoType: yup.string().required(),
			argoModel: yup.string().required(),
			lastStationTime: yup.date().required(),
			firstStationTime: yup.date().required(),
			latestCycle: yup.number().required(),
			numberOfStations: yup.number().required(),
			datafilesUrl: yup.string().required()
		})
		.camelCase()
		.required()
});

argoSchema as yup.Schema<Argo>;
