// The settings in this file are set at runtime, because they are not necessarily known at build time. They come in
// through the global `__env` object, which come from a file generated when the server starts. I don't like using
// globals like this, but I can't see any better alternative.

import * as z from "zod";

// Define the type of the global __env variable
declare global {
	interface Window {
		__env?: Record<string, string>;
	}
}

/** Schema for parsing runtime settings */
export const runtimeSettingsSchema = z.object({
	NORARGO_BACKEND_API_URL: z.string().optional(),
	CLIMATOLOGY_URL: z.string().optional()
});

/** Settings which are set at runtime */
export type RuntimeSettings = z.infer<typeof runtimeSettingsSchema>;

/** Settings which are set at runtime */
const runtimeSettings: RuntimeSettings = runtimeSettingsSchema.parse({
	NORARGO_BACKEND_API_URL: window.__env?.NORARGO_BACKEND_API_URL,
	CLIMATOLOGY_URL: window.__env?.CLIMATOLOGY_URL
});

export default runtimeSettings;
export { runtimeSettings };
