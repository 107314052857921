import { buildtimeSettings, BuildtimeSettings } from "./BuildtimeSettings";
import { runtimeSettings, RuntimeSettings } from "./RuntimeSettings";

// The following settings are optional on both the buildtime and runtime settings, but they must exist on the combined
// settings object
const NORARGO_BACKEND_API_URL = runtimeSettings.NORARGO_BACKEND_API_URL ?? buildtimeSettings.NORARGO_BACKEND_API_URL;
if (NORARGO_BACKEND_API_URL === undefined) {
	throw new Error(
		"NorArgo Backend API URL is undefined" +
			" " +
			buildtimeSettings?.NODE_ENV +
			" " +
			buildtimeSettings?.CLIMATOLOGY_URL
	);
}

const CLIMATOLOGY_URL = runtimeSettings.CLIMATOLOGY_URL ?? buildtimeSettings.CLIMATOLOGY_URL;
if (CLIMATOLOGY_URL === undefined) {
	throw new Error("Climatology API URL is undefined");
}

/**
 * Combines buildtime and runtime settings. Runtime settings take precedence over buildtime settings.
 *
 * @param bt Buildtime settings
 * @param rt Runtime settings
 *
 * @returns Combined settings
 */
const combineSettings = (bt: BuildtimeSettings, rt: RuntimeSettings): BuildtimeSettings & RuntimeSettings => {
	// The simple solution is { ...bt, ...rt }, but the problem with that is that some runtime settings may be
	// explicitly `undefined`, which will then overwrite the buildtime settings. Instead, we filter out the undefined
	// runtime settings, copying only the defined ones over to the combined settings object.

	const settings = { ...bt } as Record<string, unknown>;
	Object.entries(rt).forEach(([key, value]) => {
		if (value !== undefined) {
			settings[key] = value;
		}
	});

	// This function is dead simple in JavaScript, but getting the types right was a real pain. It is therefore type
	// hacked
	return settings as unknown as BuildtimeSettings & RuntimeSettings;
};

/** Settings for the application */
export type Settings = BuildtimeSettings &
	RuntimeSettings &
	Readonly<{ NORARGO_BACKEND_API_URL: string; CLIMATOLOGY_URL: string }>;

/** Settings for the application */
const settings: Settings = {
	...combineSettings(buildtimeSettings, runtimeSettings),
	NORARGO_BACKEND_API_URL,
	CLIMATOLOGY_URL
};

export { settings };
