import { useEffect, useRef } from "react";
import styles from "./Chart.module.scss";

interface ChartSelectTabProps {
	variables: string[];
	setVariable: (v: string) => void;
	selectedVariableName: string | undefined;
	setButtonsHeight: (height: number) => void;
}

const ChartSelectTab = ({
	variables,
	setVariable,
	selectedVariableName,
	setButtonsHeight
}: ChartSelectTabProps): JSX.Element => {
	const chartSelectTabButtonsRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Get buttons height dynamically with JS so we can calculate hightcharts height
		const buttonsHeight = chartSelectTabButtonsRef.current?.clientHeight ?? 0;
		setButtonsHeight(buttonsHeight);
	});

	return (
		<div className={"btn-group flex-wrap ms-5"} ref={chartSelectTabButtonsRef}>
			{variables.map(variable => (
				<button
					key={variable}
					onClick={() => setVariable(variable)}
					className={`${styles["variable-button"]} btn btn-secondary px-2 ${
						variable === selectedVariableName ? "active" : ""
					} btn-sm`}
				>
					{variable}
				</button>
			))}
		</div>
	);
};

export default ChartSelectTab;
export { ChartSelectTab };
