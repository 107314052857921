import { useState, useEffect, useRef } from "react";
import MapContext from "./MapContext";
import OMap from "ol/Map";
import * as proj from "ol/proj";
import "ol/ol.css";
import { LatLon } from "../../models/LatLon";
import Zoom from "ol/control/Zoom";
import styles from "./Map.module.scss";
import View, { ViewOptions } from "ol/View";
import { Argo as ArgoT } from "../../models/Argo/Argo";
import { Station } from "../../models/Argo/Station";

interface MapComponentProps {
	/** The desired zoom level of the map */
	zoom: number;
	/** Desired center of the map */
	center: LatLon;
	/** Callback for when position and/or zoom level changes */
	onMoveEnd?: (newPosition: LatLon, newZoom: number) => unknown;
	/** Projection to use */
	projection?: ViewOptions["projection"];
	children?: React.ReactNode;
	stationsInInterval: Map<ArgoT["wmo"], Station[]>;
	onStationClick: (station: Station) => void;
	getStations: (argo: ArgoT) => Station[] | null;
	onPointClick: (id: string) => void;
}

const Map = ({ zoom, center, onMoveEnd, projection, children, onPointClick }: MapComponentProps): JSX.Element => {
	const mapRef = useRef<HTMLDivElement>(null);
	const [map, setMap] = useState<OMap | null>(null);

	// Set up the map when the component mounts
	useEffect(() => {
		// This should never happen, but is there to stop TS from complaining about null
		if (mapRef.current === null) {
			return;
		}

		// Initialize the map
		const mapObject = new OMap({
			view: new View({
				projection: projection
			})
		});

		// Bind the map to the DOM
		mapObject.setTarget(mapRef.current);

		// Store the map on the state
		setMap(mapObject);

		// Clean up the map when the component is unmounted
		return () => {
			mapObject.setTarget(undefined);
			mapObject.dispose();
			setMap(null);
		};
	}, [projection]);

	// Hook up the events to the handlers
	useEffect(() => {
		// Need the map and a callback to be able to do anything
		if (map === null || onMoveEnd === undefined) {
			return;
		}

		const moveEndHandler = (): void => {
			// Get the new center and zoom from the map
			const newCenterProjected = map.getView().getCenter() ?? [0, 0];
			const newZoom = map.getView().getZoom() ?? 1;

			// Center is in the map's projection's format. Convert it to [lon, lat]
			const [lon, lat] = proj.toLonLat(newCenterProjected, map.getView().getProjection());

			// Give them to the callback
			onMoveEnd({ lat, lon }, newZoom);
		};

		// Bind the handler to the moveend event on the map
		map.on("moveend", moveEndHandler);

		return () => {
			// Clean up when the map unmounts
			map.un("moveend", moveEndHandler);
		};
	}, [map, onMoveEnd]);

	// Handle changes to the zoom and center prop
	const { lat, lon } = center; // XXX useEffect's dependency array is shallowly compared, which causes it to rerun on every render if `center` is not literally the same object. Use its parts instead
	useEffect(() => {
		// Do not run without a map
		if (map === null) {
			return;
		}

		// Center is in [lon, lat] format. Convert it to the map's projection
		// See https://openlayers.org/en/latest/doc/faq.html#why-is-the-order-of-a-coordinate-lon-lat-and-not-lat-lon-
		const projCenter = proj.fromLonLat([lon, lat], map.getView().getProjection());

		// Animate the map to the new center/zoom
		map.getView().animate({ zoom, center: projCenter, duration: 500 });

		// Remove zoom buttons (+/-)
		map.getControls().forEach(x => {
			if (x instanceof Zoom) {
				map.removeControl(x);
			}
		});
	}, [map, zoom, lat, lon]);

	useEffect(() => {
		map?.on("click", e => {
			const feature = map?.getFeaturesAtPixel(e.pixel, { hitTolerance: 10 })[0];
			if (feature !== undefined && feature !== null) {
				onPointClick(`${feature.getId() ?? ""}`);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map]);

	return (
		<MapContext.Provider value={{ map }}>
			<div ref={mapRef} className={styles["map"]}>
				{children}
			</div>
		</MapContext.Provider>
	);
};

export default Map;
export { Map };
