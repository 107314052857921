import Geometry from "ol/geom/Geometry";
import OVectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { useContext, useEffect, useMemo } from "react";
import MapContext from "../MapContext";

interface VectorLayerProps {
	/** Source for the vector */
	source?: VectorSource<Geometry>;
	/** Where in the layer stack this layer should be placed */
	zIndex: number;
}

const VectorLayer = ({ source, zIndex }: VectorLayerProps): null => {
	// Extract the map from the context
	const { map } = useContext(MapContext);

	// Store the layer between runs
	const layer = useMemo(() => new OVectorLayer({ source }), [source]);

	// Set zIndex
	layer.setZIndex(zIndex);

	// Add it to the map
	useEffect(() => {
		map?.addLayer(layer);

		return () => {
			map?.removeLayer(layer);
		};
	}, [map, layer]);

	return null;
};

export default VectorLayer;
export { VectorLayer };
