import { Argo as BArgo } from "./Argo";
import { Argo as FArgo } from "../Argo/Argo";
import { Station as BStation } from "./Station";
import { Station as FStation } from "../Argo/Station";
import { Variable as BVariable } from "./Variable";
import { Variable as FVariable } from "../Argo/Variable";
import { LatLon } from "../LatLon";

/**
 * Converts an argo from the backend format to the internal frontend format
 *
 * @param argo The argo to convert
 *
 * @returns The data on the internal frontend format
 */
const convertArgo = (argo: BArgo): FArgo => {
	const fArgo: FArgo = {
		wmo: argo.wmo,
		country: argo.globalMetadata.country,
		program: argo.globalMetadata.program,
		type: argo.globalMetadata.argoType,
		model: argo.globalMetadata.argoModel,
		firstStationTimestamp: argo.globalMetadata.firstStationTime,
		latestStationTimestamp: argo.globalMetadata.lastStationTime,
		latestCycle: argo.globalMetadata.latestCycle,
		numberOfStations: argo.globalMetadata.numberOfStations,
		datafilesUrl: argo.globalMetadata.datafilesUrl,
		metadata: {
			...argo.globalMetadata
		}
	};

	return fArgo;
};

/**
 * Converts an argo station from the backend format to the internal frontend format
 *
 * @param argo The parent argo object for the station
 * @param station The station from the backend
 *
 * @returns The data on the internal frontend format
 */
const convertStation = (argo: FArgo, station: BStation): FStation => {
	let pos: LatLon | undefined = {
		lat: station.stationData.latitude,
		lon: station.stationData.longitude
	};
	if (Math.abs(pos?.lat ?? 91) > 90 || Math.abs(pos?.lon ?? 181) > 180) {
		// Argo didn't manage to get position for this station
		pos = undefined;
	}

	const fStation: FStation = {
		pos,
		timestamp: new Date(station.stationData.time),
		wmo: argo.wmo,
		number: station.stationNumber,
		cycle: station.stationData.cycleNumber,
		metadata: {
			...station.stationData
		}
	};

	return fStation;
};

/**
 * Converts an argo station variable from the backend format to the internal frontend format
 *
 * @param station The station from the backend
 * @param variable The variable to convert
 *
 * @returns The data on the internal frontend format
 */
const convertVariable = (station: FStation, variable: BVariable): FVariable => ({
	wmo: station.wmo,
	stationNumber: station.number,
	name: variable.variableName,
	data: variable.datapoints.map(([depth, value]) => ({ depth, value })),
	unit: variable.metadata.units,
	adjustedDataExists: variable.metadata.adjustedDataExists,
	metadata: {
		...variable.metadata
	}
});

export { convertArgo, convertStation, convertVariable };
