import { useEffect, useState } from "react";
import { Chart } from "./Chart";
import { ChartMetadata } from "./ChartMetadata";
import { ChartSelectTab } from "./ChartSelectTab";
import styles from "./Chart.module.scss";
import { Argo } from "../../models/Argo/Argo";
import { Station } from "../../models/Argo/Station";
import { Variable } from "../../models/Argo/Variable";
import { useTranslation } from "react-i18next";
import ArgoManager from "../../models/ArgoManager";
import { Spinner } from "../spinner/Spinner";
import { ClimatologyResponse, getClimatology } from "../../models/Backend/ClimatologyApi";
import InvalidStations from "../invalidStations/InvalidStations";

interface ChartContainerProps {
	argo: Argo;
	argoManager: ArgoManager;
	stations: Station[];
	focusedStation: Station;
	profileDateStringified: string;
	onStationChange: (direction: "next" | "prev") => void;
	height: number;
}

const ChartContainer = ({
	argo,
	argoManager,
	stations,
	focusedStation,
	profileDateStringified,
	onStationChange,
	height
}: ChartContainerProps): JSX.Element | null => {
	const { t } = useTranslation();
	const [chartSelectButtonsHeight, setChartSelectButtonsHeight] = useState(0);
	const modalPaddingAndNextPrevButtonsHeight = 75;

	/*********************
	 * Variable fetching *
	 *********************/

	const [adjusted, setAdjusted] = useState(false);

	// Fetch the variables for the focused station
	const [focusedStationVariables, setFocusedStationVariables] = useState<Variable[] | null>(null);

	// Whether or not to display the shadow data
	const [displayShadowData, setDisplayShadowData] = useState(false);
	const [displayClimatologyProfile, setDisplayClimatologyProfile] = useState(true);
	const [meanAndStDevData, setMeanAndStDevData] = useState<ClimatologyResponse>();
	const [shadowVariablesMap, setShadowVariablesMap] = useState<Map<Station, Variable[]> | null>(null);
	const [numberOfInvalidStations, setNumberOfInvalidStations] = useState(0);

	/***********************
	 * Using the variables *
	 ***********************/

	const variables = focusedStationVariables;
	const variableNames = variables?.map(variable => variable.name);
	const [selectedVariableName, setSelectedVariableName] = useState<undefined | string>();
	const selectedVariableIndex = variables?.findIndex(variable => variable.name === selectedVariableName) ?? -1;
	// Set the variable name to be the selected variable. If it does not exist, then variable name should be the first from the variableNames array
	useEffect(() => {
		if (!variableNames?.includes(selectedVariableName ?? "")) {
			setSelectedVariableName(variableNames?.[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variableNames, setSelectedVariableName]);

	const convertToApiVariable = (variableName: string | undefined): string => {
		switch (variableName) {
			case "TEMP":
				return "temperature";
			case "PSAL":
				return "salinity";
			default:
				return "";
		}
	};

	useEffect(() => {
		const { lat: latitude, lon: longitude } = focusedStation.pos ?? { lat: 0, lon: 0 };
		const date = new Date(profileDateStringified);
		const variableName = convertToApiVariable(selectedVariableName);
		const emptyClimatology = { variableName: "NA", dataList: [], latitude, longitude, month: "" };
		setMeanAndStDevData(emptyClimatology);

		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		variableName !== "" &&
			getClimatology({ latitude, longitude, date, variableName })
				.then(climatology => {
					setMeanAndStDevData(climatology);
				})
				.catch(err => {
					console.error("Failed to fetch Climatology", err);
					setMeanAndStDevData(emptyClimatology);
				});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedVariableName, focusedStation]);

	const variable = variables?.[selectedVariableIndex];
	const dataPointList = variable?.data ?? [];
	const unit = variable?.unit;
	const adjustedDataExists = variable?.adjustedDataExists ?? false;

	// Set all shadow points as a DataPointList[][]
	const shadowPointLists = [];
	for (const variables of shadowVariablesMap?.values() ?? new Map<Station, Variable[]>().values()) {
		const points = variables.find(variable => variable.name === selectedVariableName)?.data ?? [];
		shadowPointLists.push(points);
	}

	/***********
	 * Effects *
	 ***********/

	// XXX Ideally these should be right under where the state they modify is defined, but due to dependencies
	// (specifically `adjusted` and `variable`, this is difficult)

	const showAdjustedData = adjusted && adjustedDataExists;

	// Fetch data for this station
	useEffect(() => {
		let isMounted = true;

		void (async () => {
			const variablesMap = await argoManager.fetchVariables([focusedStation], { adjusted: showAdjustedData });

			if (isMounted) {
				setFocusedStationVariables(variablesMap.get(focusedStation) ?? null);
			}
		})();

		return () => {
			isMounted = false;
		};
	}, [focusedStation, argoManager, showAdjustedData]);

	// Fetch the shadow data
	useEffect(() => {
		let isMounted = true;

		// No point fetching shadow data if it should not be displayed
		if (!displayShadowData) {
			return;
		}

		void (async () => {
			const variablesMap = await argoManager.fetchVariables(stations, { adjusted: showAdjustedData });
			if (isMounted) {
				setShadowVariablesMap(variablesMap);
				const number = (Array.from(variablesMap.values()).map(v => (v.length > 0 ? 0 : 1)) as number[]).reduce(
					(a, b) => a + b,
					0
				);
				setNumberOfInvalidStations(number);
			}
		})();

		return () => {
			isMounted = false;
		};
	}, [stations, argoManager, displayShadowData, showAdjustedData]);

	/**********
	 * Render *
	 **********/

	return (
		<div className={`${styles["chart-container"]}`}>
			<div className={styles["inherit-flex"]}>
				<div className={styles["chart-metadata"]}>
					{focusedStation && Object.keys(focusedStation).length > 0 && (
						<div>
							<ChartMetadata
								argo={argo}
								stationData={focusedStation}
								profileDateStringified={profileDateStringified}
							/>
							{/* {selectedVariableName !== undefined && (
								<CsvBuilder
									stations={stations}
									selectedVariableName={selectedVariableName}
									shadowVariablesMap={shadowVariablesMap}
									displayShadowData={displayShadowData}
								/>
							)} */}
						</div>
					)}

					<div>
						<div>
							{adjusted && !adjustedDataExists && (
								<p className="text-danger">
									{t("frontpage:chart:adjustedValuesDontExistShowingMeasuredInstead")}
								</p>
							)}
							<label className="form-check-label ms-1 me-1">
								<input
									className="form-check-input me-1"
									type="radio"
									disabled={!adjustedDataExists}
									onChange={() => setAdjusted(false)}
									checked={!adjustedDataExists || !adjusted}
								/>
								{t("frontpage:chart:measuredValues")}
							</label>
						</div>
						<div>
							<label className="form-check-label ms-1">
								<input
									className="form-check-input me-1"
									type="radio"
									disabled={!adjustedDataExists}
									onChange={() => setAdjusted(true)}
									checked={adjustedDataExists && adjusted}
								/>
								{t("frontpage:chart:adjustedValues")}
							</label>
						</div>
					</div>
					<div className="mt-3">
						<div>
							<label>
								<input
									type="checkbox"
									onChange={e => setDisplayShadowData(e.target.checked)}
									checked={displayShadowData}
									className={styles["margin-left-metadata-text"]}
								/>{" "}
								{t("frontpage:chart:showAllProfiles")}
							</label>
						</div>
						<div>
							<label>
								<input
									type="checkbox"
									onChange={e => setDisplayClimatologyProfile(e.target.checked)}
									checked={displayClimatologyProfile}
									className={styles["margin-left-metadata-text"]}
								/>{" "}
								{t("frontpage:chart:showClimatologyProfile")} (
								<a href="https://www.ncei.noaa.gov/access/world-ocean-atlas-2023/">1991-2020</a>){" "}
								{t("frontpage:chart:showClimatologyProfileInfo")}
							</label>
						</div>
					</div>
					{displayShadowData && shadowVariablesMap === null && <Spinner />}
					{displayShadowData && numberOfInvalidStations > 0 && (
						<InvalidStations numberOfInvalidStations={numberOfInvalidStations} />
					)}
				</div>
				<div className={styles["chart-outer"]}>
					<div className={styles["chart"]}>
						{variableNames?.length && (
							<ChartSelectTab
								variables={variableNames}
								setVariable={setSelectedVariableName}
								selectedVariableName={selectedVariableName}
								setButtonsHeight={setChartSelectButtonsHeight}
							/>
						)}
						{selectedVariableName && unit && (
							<Chart
								data={dataPointList}
								shadowData={shadowPointLists ?? []}
								displayShadowData={displayShadowData}
								displayClimatologyProfile={displayClimatologyProfile}
								unit={unit}
								height={height - chartSelectButtonsHeight - modalPaddingAndNextPrevButtonsHeight}
								meanAndStDevData={meanAndStDevData}
							/>
						)}
					</div>
				</div>
			</div>
			<div>
				<div className={styles["next-previous-profile-buttons"]}>
					<button onClick={() => onStationChange("prev")} type="button" className="btn btn-primary btn-sm">
						{t("frontpage:chart:previous")}
					</button>
					<button
						className="btn btn-primary btn-sm"
						onClick={() => onStationChange("next")}
						style={{ zIndex: 2 }}
						disabled={focusedStation.cycle === stations.length}
					>
						{t("frontpage:chart:next")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ChartContainer;
export { ChartContainer };
