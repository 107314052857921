import styles from "./spinner.module.scss";
import PulseLoader from "react-spinners/PulseLoader";

const Spinner = (): JSX.Element => {
	return (
		<div className={styles["spinner"]}>
			<PulseLoader loading={true} color={"gray"} />
		</div>
	);
};

export { Spinner };
export default Spinner;
