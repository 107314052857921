import { useEffect, useState } from "react";
import { SeaAreas } from "../../models/SeaAreas";
import styles from "./SeaAreasStyle.module.scss";
import * as Backend from "../../models/Backend/";
import { useTranslation } from "react-i18next";

/**
 * Takes in a sea-area-object and displays it in a SeaAreaStatusBar (JSX.Element)
 * Each sea-area is put in a <p>-tag. Each word in the sea-area is separated and capitalized (it expects camelCase).
 * Sea-area count is put in a <span> for easy styling.
 * @param seaAreas: SeaArea object to be displalyed.
 * @returns SeaAreaStatusBar.
 */
const SeaAreaStatusBar = (): JSX.Element | null => {
	const { t } = useTranslation();

	const [seaAreas, setSeaAreas] = useState<SeaAreas | null>(null);
	useEffect(() => {
		let isMounted = true;

		void Backend.getSeaAreas().then(seaAreas => {
			if (isMounted) {
				setSeaAreas(seaAreas);
			}
		});

		return () => {
			isMounted = false;
		};
	}, []);
	if (seaAreas === null) {
		return null;
	}

	return (
		<div className={styles["sea-areas"]}>
			<p className={styles["active-floats-description"]}>{t("frontpage:seaareas:numberOfActiveFloats")}:</p>
			{Object.entries(seaAreas).map(([name, floatCount]) => (
				<p key={name} className={styles["sea-area"]}>
					{name.replace(/([A-Z])/g, " $1").toUpperCase()}: {floatCount}
				</p>
			))}
		</div>
	);
};
export { SeaAreaStatusBar };
