import TreeBuilder from "../tree/TreeBuilder";
import { Argo } from "../../models/Argo/Argo";
import DateIntervalSelector from "../dateselector/DateIntervalSelector";
import { useMemo } from "react";

interface SidebarProps {
	argos: Argo[];
	getShowPath: (argo: Argo) => boolean;
	getShowStations: (argo: Argo) => boolean;
	setShowPath: (argo: Argo, showPath: boolean) => void;
	setShowStations: (argo: Argo, showStations: boolean) => void;
	onDateIntervalChange: (e: Interval) => void;
	initialDateInterval: Interval;
	showWithinInterval: boolean;
	setShowWithinInterval: (showWithinInterval: boolean) => void;
	showPathForArgos: boolean;
	setShowPathForArgos: (showPathForArgos: boolean) => void;
	selectedCountries: Set<string>;
	setCountrySelected: (country: string, checked: boolean) => void;
	selectedArgoTypes: Set<string>;
	setArgoTypeSelected: (country: string, checked: boolean) => void;
	setShowInfoBox: (showInfoBox: boolean) => void;
}
/**
 * Sidebar component.
 * @param argos: argos that will be displayed in the sidebar.
 * @param onDateIntervalFilter: State setter passed down. Is used in component further down.
 * @returns Sidebar component.
 */
const Sidebar = ({
	argos,
	getShowPath,
	getShowStations,
	setShowPath,
	setShowStations,
	onDateIntervalChange,
	initialDateInterval,
	showWithinInterval,
	setShowWithinInterval,
	showPathForArgos,
	setShowPathForArgos,
	selectedCountries,
	setCountrySelected,
	selectedArgoTypes,
	setArgoTypeSelected,
	setShowInfoBox
}: SidebarProps): JSX.Element => {
	// Sort the argos by their WMO. This is a somewhat expensive operation, so memoize it
	const sortedArgos = useMemo(() => [...argos].sort((a, b) => (a.wmo > b.wmo ? 1 : -1)), [argos]);

	return (
		<>
			<DateIntervalSelector
				onDateIntervalChange={onDateIntervalChange}
				initialDateInterval={initialDateInterval}
			></DateIntervalSelector>
			<TreeBuilder
				argos={sortedArgos}
				getShowPath={getShowPath}
				getShowStations={getShowStations}
				setShowPath={setShowPath}
				setShowStations={setShowStations}
				interval={initialDateInterval}
				showWithinInterval={showWithinInterval}
				setShowWithinInterval={setShowWithinInterval}
				showPathForArgos={showPathForArgos}
				setShowPathForArgos={setShowPathForArgos}
				selectedCountries={selectedCountries}
				setCountrySelected={setCountrySelected}
				selectedArgoTypes={selectedArgoTypes}
				setArgoTypeSelected={setArgoTypeSelected}
				setShowInfoBox={setShowInfoBox}
			/>
		</>
	);
};

export default Sidebar;
export { Sidebar };
