import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import RegularShape from "ol/style/RegularShape";

/**
 * Get a RegularShape object based on Argo-type.
 * @param type: The Argo-type.
 * @param color: Color of shape-fill.
 * @param shapeSize: Size of the shape.
 * @returns A styled RegularShape.
 */
const getArgoTypeShape = (type: string | undefined, color: string, shapeSize: number) => {
	const strokeWidth = shapeSize / 3;

	const circle = new RegularShape({
		fill: new Fill({ color: color }),
		stroke: new Stroke({ width: strokeWidth }),
		points: 32,
		radius: shapeSize - 1
	});

	const triangle = new RegularShape({
		fill: new Fill({ color: color }),
		stroke: new Stroke({ width: strokeWidth }),
		points: 3,
		radius: shapeSize
	});

	const diamond = new RegularShape({
		fill: new Fill({ color: color }),
		stroke: new Stroke({ width: strokeWidth }),
		points: 4,
		radius: shapeSize
	});

	const pentagon = new RegularShape({
		fill: new Fill({ color: color }),
		stroke: new Stroke({ width: strokeWidth }),
		points: 5,
		radius: shapeSize
	});

	const missingType = new RegularShape({
		fill: new Fill({ color: color }),
		stroke: new Stroke({ width: strokeWidth }),
		points: 5,
		radius: shapeSize,
		radius2: 0
	});

	const typeMap = new Map<string, RegularShape>([
		["core", circle],
		["deep", triangle],
		["bgc", pentagon],
		["bio", diamond]
	]);

	// Handle undefined type. Or type not in TypeMap (?? missingType)
	const argoType = type ?? "missing";
	typeMap.set("missing", missingType);
	return typeMap.get(argoType.toLowerCase()) ?? missingType;
};

/**
 * Get a Style object based on the country the argo belongs to, and what type it is.
 * @param country: Country which the Argo belongs too.
 * @param argoType: Type of the Argo.
 * @returns A Style object.
 */
const styleForCountryAndType = (country: string, firstStation?: boolean, argoType?: string): Style => {
	let floatColor = "";
	if (firstStation) {
		floatColor = "#00ff00";
	} else {
		floatColor = country.toLowerCase() === "norway" ? "#f03e3e" : "#ffd700";
	}
	const shapeSize = 6;
	const style = new Style({
		image: getArgoTypeShape(argoType, floatColor, shapeSize),
		// Color and width of float-path.
		stroke: new Stroke({ color: floatColor, width: shapeSize / 2 })
	});
	return style;
};

/**
 * Sets marker from public folder as openlayer Style
 * @returns An openlayers Style
 */
const styleForMarker = (): Style => {
	// XXX: anchor decides margin of the pointer. Should get proper numbers. Getting a new image will break the marker.
	const style = new Style({
		image: new Icon({
			anchor: [10, 33],
			anchorXUnits: "pixels",
			anchorYUnits: "pixels",
			src: "marker.png"
		})
	});
	return style;
};

export { styleForCountryAndType as floatStyle, styleForMarker as floatMarker };
