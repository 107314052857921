import ArgoManager from "../../models/ArgoManager";
import FrontpageMap from "../frontpage/Map";
import styles from "./Frontpage.module.scss";
import FrontpageBanner from "../frontpage/Banner";
import Sidebar from "../frontpage/Sidebar";
import { useEffect, useState } from "react";
import { Argo } from "../../models/Argo/Argo";
import { ChartContainer } from "../charts/ChartContainer";
import { Station } from "../../models/Argo/Station";
import { format, startOfHour, subDays, isWithinInterval } from "date-fns";
import { SeaAreaStatusBar } from "../seaAreas/SeaAreasStatusBar";
import { Modal } from "../modals/Modal";
import { Spinner } from "../spinner/Spinner";
import { useTranslation } from "react-i18next";
import { WindowSize } from "../../models/WindowsSize";
import { Infoxbox } from "../Infobox/Infobox";

/** The component's global argo manager */
const argoManager = new ArgoManager();

const Frontpage = (): JSX.Element => {
	const { t } = useTranslation();
	const [modalSize, setModalSize] = useState<WindowSize>({ width: 600, height: 600 });
	const [showInfoBox, setShowInfoBox] = useState(false);

	/*********
	 * Argos *
	 *********/

	// All argos the frontpage knows about
	const [argos, setArgos] = useState<Argo[] | null>(null);
	useEffect(() => {
		let isMounted = true;

		void argoManager.fetchArgos().then(argos => {
			if (isMounted) {
				setArgos(argos);
			}
		});

		return () => {
			isMounted = false;
		};
	}, []);

	/***************************************
	 * All stations within a time interval *
	 ***************************************/

	const now = startOfHour(new Date());
	const [interval, setInterval] = useState<Interval>({ start: subDays(now, 60), end: now });
	const [stationsInInterval, setStationsInInterval] = useState(new Map<Argo["wmo"], Station[]>());
	const [clickedStationIdString, setClickedStationIdString] = useState<string>("");

	useEffect(() => {
		const id = `${clickedStationIdString ?? ""}`.split("_");
		let stations: Station[] | null | undefined = stationsInInterval.get(id[0]);

		// Check if the station is in the interval already cached
		let station: Station | undefined = stations?.filter(station => station.number === parseInt(id[1]))[0];
		if (station) {
			// console.log("Found station in StationInterval at pixel", station);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			handleStationClick(station as unknown as Station);
			// RETURN HERE
			// So that we don't fetch the station again and redraw the map again
			return;
		}

		// Check if the station is in the entire cache
		stations = getCachedStations({ wmo: id[0] } as Argo);

		station = stations?.filter(station => station.number === parseInt(id[1]))[0] ?? undefined;
		if (station) {
			// console.log("Found station at pixel", station);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			handleStationClick(station as unknown as Station);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clickedStationIdString]);

	useEffect(() => {
		if (argos === null) {
			return;
		}

		let isMounted = true;

		void (async () => {
			const argoStationTuples = await Promise.all(
				argos
					// Fetch only for argos which have stations within the interval
					.filter(argo => {
						const argoInterval = { start: argo.firstStationTimestamp, end: argo.latestStationTimestamp };

						return (
							isWithinInterval(argo.latestStationTimestamp, interval) ||
							isWithinInterval(argo.firstStationTimestamp, interval) ||
							isWithinInterval(interval.start, argoInterval) ||
							isWithinInterval(interval.end, argoInterval)
						);
					})
					// Fetch all stations within the interval for this argo
					.map(async argo => {
						const stations = await argoManager.fetchStations(
							argo,
							new Date(interval.start),
							new Date(interval.end)
						);

						return [argo.wmo, stations] as const;
					})
			);

			if (!isMounted) {
				return;
			}

			// Update the state
			setStationsInInterval(new Map(argoStationTuples));
		})();

		return () => {
			isMounted = false;
		};
	}, [argos, interval]);

	const [showWithinInterval, setShowWithinInterval] = useState(true);
	const [showPathForArgos, setShowPathForArgos] = useState(true);

	/**********************************************************
	 * State for which argos to show all paths for on the map *
	 **********************************************************/

	const [displayArgos, setDisplayArgos] = useState<Map<Argo["wmo"], { path?: boolean; stations?: boolean }>>(
		new Map()
	);

	/**
	 * Checks whether or not an argo's path or stations should be displayed on the map
	 *
	 * @param argo The argo to get display state of
	 * @param what Which display state to get. Either "path" or "stations"
	 *
	 * @returns True if the state should be displayed, false otherwise
	 */
	const getDisplayArgo = ({ wmo }: Argo, what: "path" | "stations") => displayArgos.get(wmo)?.[what] ?? false;

	/**
	 * Sets whether or not to display paths or stations for an argo on the map
	 *
	 * @param wmo WMO of the argo to set display state of
	 * @param what Which display state to set. Either "path" or "stations"
	 * @param display Whether to display the state or not
	 */
	const setDisplayArgo = (argo: Argo, what: "path" | "stations", display: boolean): void => {
		// If the argo should be displayed, we need something to display. Cache the argo's stations
		if (display) {
			void cacheStationsFor(argo);
		}

		setDisplayArgos(displayArgos => {
			// Make a new map, to keep state immutable
			const newDisplayArgos = new Map(displayArgos);

			// Get the current state from the map. Default to empty object
			const displayState = newDisplayArgos.get(argo.wmo) ?? {};

			// Create a new map with the desired state set to desired value
			const newDispalyState = { ...displayState, [what]: display };

			// Put it back in the map and return it
			newDisplayArgos.set(argo.wmo, newDispalyState);
			return newDisplayArgos;
		});
	};

	/**
	 * Checks whether or not the path of an argo should be shown on the map
	 *
	 * @param argo The argo to check
	 *
	 * @returns True of the path should be shown, false otherwise
	 */
	const getShowPath = (argo: Argo) => getDisplayArgo(argo, "path");

	/**
	 * Checks whether or not the stations of an argo should be shown on the map
	 *
	 * @param argo The argo to check
	 *
	 * @returns True of the stations should be shown, false otherwise
	 */
	const getShowStations = (argo: Argo) => getDisplayArgo(argo, "stations");

	/**
	 * Sets whether or not to show the path of an argo on the map
	 *
	 * @param argo Argo to set the state for
	 * @param showPath Whether or not to display the path on the map
	 */
	const setShowPath = (argo: Argo, showPath: boolean) => setDisplayArgo(argo, "path", showPath);

	/**
	 * Sets whether or not to show the stations of an argo on the map
	 *
	 * @param argo Argo to set the state for
	 * @param showPath Whether or not to display the stations on the map
	 */
	const setShowStations = (argo: Argo, showStations: boolean) => setDisplayArgo(argo, "stations", showStations);

	/***************************************************
	 * State for which argos selected from the sidebar *
	 ***************************************************/

	// Keeps count of how many checkboxes have been manually checked by the user per argo
	const [manuallyCheckedArgos, setManuallyCheckedArgos] = useState<Map<Argo["wmo"], number>>(new Map());

	/**
	 * Adjusts the count of how many checkboxes have been manually checked in the sidebar for an argo
	 *
	 * @param argo The argo to adjust the number for
	 * @param manuallychecked Whether a checkbox was checked or unchecked
	 */
	const setManuallyChecked = ({ wmo }: Argo, checked: boolean): void =>
		setManuallyCheckedArgos(manuallyCheckedArgos => {
			// Figure out if one should be added or subtracted
			const delta = checked ? 1 : -1;

			// Create a new map to keep immutability
			const newManuallyCheckedArgos = new Map(manuallyCheckedArgos);

			// Update the state
			const currentCount = newManuallyCheckedArgos.get(wmo) ?? 0;
			newManuallyCheckedArgos.set(wmo, currentCount + delta);

			return newManuallyCheckedArgos;
		});

	/**
	 * Checks whether or not an argo is manually checked in the sidebar
	 *
	 * @param argo The argo to check
	 *
	 * @returns True if the user has manually checked it, false otherwise
	 */
	const isManuallyChecked = ({ wmo }: Argo): boolean => (manuallyCheckedArgos.get(wmo) ?? 0) > 0;

	const hideIfNotManuallyChecked = (argo: Argo): void => {
		if (!isManuallyChecked(argo)) {
			// Remove from sidebar
			setShowStations(argo, false);
			setShowPath(argo, false);
		}
	};

	/** Special setShowPath for the sidebar. @see setShowPath */
	const sidebarSetShowPath = (argo: Argo, showPath: boolean): void => {
		setManuallyChecked(argo, showPath);
		setShowPath(argo, showPath);
	};

	/** Special sidebarSetShowStations for the sidebar. @see setShowStations */
	const sidebarSetShowStations = (argo: Argo, showStations: boolean): void => {
		setManuallyChecked(argo, showStations);
		setShowStations(argo, showStations);
	};

	/******************
	 * Stations cache *
	 ******************/

	const [stationsCache, setStationsCache] = useState<Map<Argo["wmo"], Station[]>>(new Map());

	/**
	 * Gets the cached stations for an argo
	 *
	 * @param argo The argo to get the cached stations for
	 *
	 * @returns The cached stations, if they exist. Otherwise null
	 */
	const getCachedStations = ({ wmo }: Argo) => stationsCache.get(wmo) ?? null;

	/**
	 * Fetches all stations for an argo and puts them in the cache
	 *
	 * @param argo Argo to cache stations for
	 */
	const cacheStationsFor = async ({ wmo }: Argo) => {
		// No need to recache stations which are already cached
		if (stationsCache.has(wmo)) {
			return;
		}

		// Get all the argo's stations
		const argo = await argoManager.fetchArgo(wmo);
		if (argo === null) {
			// This should never happen
			throw new Error("Invalid argo");
		}
		const stations = await argoManager.fetchAllStations(argo);

		// Shove them into the cache
		setStationsCache(stationsCache => {
			const newStationsCache = new Map(stationsCache);
			newStationsCache.set(wmo, stations);
			return newStationsCache;
		});
	};

	/*****************************************
	 * Current argo and the station in focus *
	 *****************************************/

	// The currently focused station
	const [focusedStation, setFocusedStation] = useState<Station | null>(null);
	const focusedArgo = argos?.find(argo => focusedStation?.wmo === argo.wmo ?? false) ?? null;
	const focusedArgoStations = focusedArgo !== null ? getCachedStations(focusedArgo) : null;
	const profileDateStringified = focusedStation ? format(focusedStation.timestamp, "yyyy-MM-dd HH:mm") : "";

	// User clicks back or forth between stations
	const onStationChange = (direction: "next" | "prev"): void => {
		if (focusedArgoStations && focusedStation) {
			const currentStationIndex = focusedArgoStations.findIndex(
				station => station.number === focusedStation.number
			);
			if (direction === "next") {
				// Tries to move to next station
				if (!(currentStationIndex + 1 >= focusedArgoStations.length)) {
					setFocusedStation(focusedArgoStations[currentStationIndex + 1]);
				}
			} else if (direction === "prev") {
				// Tries to move to previous station
				if (!(currentStationIndex <= 0)) {
					setFocusedStation(focusedArgoStations[currentStationIndex - 1]);
				}
			}
		}
	};

	/*****************************************
	 * State for selected country in sidebar *
	 *****************************************/

	const [selectedCountries, setSelectedCountries] = useState<Set<string>>(new Set());
	const setCountrySelected = (country: string, checkedCountry: boolean): void => {
		setSelectedCountries(selectedCountries => {
			const newCountriesToShow = new Set(selectedCountries);
			checkedCountry ? newCountriesToShow.add(country) : newCountriesToShow.delete(country);
			return newCountriesToShow;
		});
	};

	/**************************************
	 * State for selected type in sidebar *
	 **************************************/

	const [selectedArgoTypes, setSelectedArgoTypes] = useState<Set<string>>(new Set());
	const setArgoTypeSelected = (argoType: string, checkedArgoType: boolean): void => {
		setSelectedArgoTypes(selectedArgoTypes => {
			const newCountriesToShow = new Set(selectedArgoTypes);
			checkedArgoType ? newCountriesToShow.add(argoType) : newCountriesToShow.delete(argoType);
			return newCountriesToShow;
		});
	};

	/**********
	 * Render *
	 **********/

	/** Handle closing of the graph modal */
	const handleModalClose = (): void => {
		if (focusedArgo !== null) {
			hideIfNotManuallyChecked(focusedArgo);
		}
		setFocusedStation(null);
		setClickedStationIdString("");
	};

	/**
	 * Handles clicks on stations in the map
	 *
	 * @param station The clicked station
	 */
	const handleStationClick = async (station: Station): Promise<void> => {
		// If the station belongs to another argo than the previous, run hiding logic
		if (focusedArgo !== null && focusedStation !== null && focusedStation.wmo !== station.wmo) {
			hideIfNotManuallyChecked(focusedArgo);
		}

		setFocusedStation(station);

		const argo = await argoManager.fetchArgo(station.wmo);
		if (argo === null) {
			// Should never happen
			throw new Error("Invalid argo");
		}

		setShowPath(argo, true);
		setShowStations(argo, true);
	};

	const handlePointClick = (idStr: string) => {
		setClickedStationIdString(idStr);
	};

	// Filter argos on country, and then on type
	let filteredArgosForMap = argos;
	if (filteredArgosForMap !== null) {
		if (selectedCountries.size !== 0) {
			// Filter on country
			filteredArgosForMap = filteredArgosForMap.filter(argo => selectedCountries.has(argo.country));
		}
		if (selectedArgoTypes.size !== 0) {
			// Filter on type
			filteredArgosForMap = filteredArgosForMap.filter(argo => selectedArgoTypes.has(argo.type));
		}
	}

	return (
		<div className={styles["layout"]}>
			<header>
				<FrontpageBanner />
			</header>
			<main>
				<div className={styles["sidebar"]}>
					{argos === null ? (
						<Spinner />
					) : (
						<>
							<Sidebar
								argos={argos}
								getShowPath={getShowPath}
								getShowStations={getShowStations}
								setShowPath={sidebarSetShowPath}
								setShowStations={sidebarSetShowStations}
								onDateIntervalChange={setInterval}
								initialDateInterval={interval}
								showWithinInterval={showWithinInterval}
								setShowWithinInterval={setShowWithinInterval}
								showPathForArgos={showPathForArgos}
								setShowPathForArgos={setShowPathForArgos}
								selectedCountries={selectedCountries}
								setCountrySelected={setCountrySelected}
								selectedArgoTypes={selectedArgoTypes}
								setArgoTypeSelected={setArgoTypeSelected}
								setShowInfoBox={setShowInfoBox}
							/>
							<Infoxbox show={showInfoBox} onClose={() => setShowInfoBox(false)} />
						</>
					)}
				</div>
				<div className={styles["map-container"]}>
					<SeaAreaStatusBar />
					<FrontpageMap
						argos={filteredArgosForMap}
						stationsInInterval={showWithinInterval ? stationsInInterval : new Map()}
						getStations={getCachedStations}
						getShowPath={getShowPath}
						getShowStations={getShowStations}
						focusedStation={focusedStation}
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						onStationClick={handleStationClick}
						onPointClick={handlePointClick}
						showPathForArgos={showPathForArgos}
					/>
				</div>
				{focusedStation !== null && focusedArgo !== null ? (
					<Modal
						title={t("frontpage:chart:profile") + " " + profileDateStringified}
						onClose={handleModalClose}
						modalSize={modalSize}
						setModalSize={setModalSize}
					>
						<ChartContainer
							argo={focusedArgo}
							argoManager={argoManager}
							stations={focusedArgoStations ?? []}
							focusedStation={focusedStation}
							profileDateStringified={profileDateStringified}
							onStationChange={onStationChange}
							height={modalSize.height}
						/>
					</Modal>
				) : null}
			</main>
		</div>
	);
};

export default Frontpage;
export { Frontpage };
