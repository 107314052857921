import { Feature } from "ol";
import { Argo as ArgoT } from "../../../../models/Argo/Argo";
import { Station } from "../../../../models/Argo/Station";
import { LatLon } from "../../../../models/LatLon";
import { PathFeature } from "./Path";
import { StationFeatures } from "./Stations";
import Geometry from "ol/geom/Geometry";
import VectorLayer from "../VectorLayer";
import { useEffect, useMemo } from "react";
import VectorSource from "ol/source/Vector";

const getFilteredStations = (stations: Station[] | undefined | null): (Station & { pos: LatLon })[] =>
	(stations ?? []).filter(station => station.pos !== undefined) as (Station & {
		pos: LatLon;
	})[];

interface ArgoFeatureProps {
	argo: ArgoT;
	stations: Station[];
	showStations: boolean;
	onStationClick: (station: Station) => void;
	zIndex: number;
	focusedStation: Station | null;
	showLastStation: boolean;
}

const ArgoFeatures = ({
	argo,
	stations,
	showStations,
	onStationClick,
	zIndex,
	focusedStation,
	showLastStation
}: ArgoFeatureProps): Map<Feature<Geometry>, Station> => {
	// Some stations do not know their own position. They naturally cannot be shown on the map
	const filteredStations = getFilteredStations(stations);
	const stationsMap = StationFeatures({
		zIndex: zIndex,
		onStationClick,
		country: argo.country,
		argoType: argo.type,
		argoFirstStationTimeStamp: argo.firstStationTimestamp,
		focusedStation: focusedStation,
		stations: showStations
			? filteredStations
			: showLastStation
			? filteredStations.slice(-1)
			: [] /* Show last station for dateInterval argos but not from sidebar */
	});
	return stationsMap;
};

interface ArgoLayerProps {
	argos: ArgoT[] | null;
	onStationClick: (station: Station) => void;
	stationsInInterval: Map<ArgoT["wmo"], Station[]>;
	focusedStation: Station | null;
	getStations: (argo: ArgoT) => Station[] | null;
	getShowStations: (argo: ArgoT) => boolean;
	getShowPath: (argo: ArgoT) => boolean;
	showPathForArgos: boolean;
}

const ArgoLayerActiveStation = ({
	argos,
	onStationClick,
	focusedStation,
	getStations,
	getShowStations,
	getShowPath
}: ArgoLayerProps): JSX.Element => {
	const featureStationMap: Map<Feature<Geometry>, Station> = new Map([]);
	const source = useMemo(() => new VectorSource({ features: [] }), []);

	useEffect(() => {
		source.clear();
		{
			(argos ?? [])
				.filter(argo => getStations(argo) ?? false)
				.map(argo => {
					const path = getShowPath(argo)
						? PathFeature({
								zIndex: 1,
								country: argo.country,
								stations: getFilteredStations(getStations(argo))
						  })
						: null;

					const m = ArgoFeatures({
						showStations: getShowStations(argo),
						onStationClick,
						zIndex: 2,
						argo: argo,
						stations: getStations(argo) ?? [],
						focusedStation,
						showLastStation: false
					});
					m.forEach((value, key) => {
						featureStationMap.set(key, value);
					});

					if (path) {
						source.addFeature(path);
					}

					source.addFeatures(Array.from(m.keys()));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [argos, getStations, getShowPath, getShowStations, focusedStation, onStationClick]);

	return <VectorLayer zIndex={1} source={source} />;
};

const ArgoLayerStationInterval = ({
	argos,
	onStationClick,
	stationsInInterval,
	focusedStation,
	showPathForArgos
}: ArgoLayerProps): JSX.Element => {
	const featureStationMap: Map<Feature<Geometry>, Station> = new Map([]);
	const source = useMemo(() => new VectorSource({ features: [] }), []);

	useEffect(() => {
		source.clear();
		{
			(argos ?? [])
				.filter(argo => stationsInInterval.has(argo.wmo))
				.map(argo => {
					const path = showPathForArgos
						? PathFeature({
								zIndex: 1,
								country: argo.country,
								stations: getFilteredStations(stationsInInterval.get(argo.wmo))
						  })
						: null;
					const m = ArgoFeatures({
						showStations: false,
						onStationClick,
						zIndex: 1,
						argo: argo,
						stations: stationsInInterval.get(argo.wmo) ?? [],
						focusedStation,
						showLastStation: true
					});
					if (path) {
						source.addFeature(path);
					}
					m.forEach((value, key) => {
						featureStationMap.set(key, value);
					});

					source.addFeatures(Array.from(m.keys()));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [argos, stationsInInterval, focusedStation, onStationClick]);

	return <VectorLayer zIndex={1} source={source} />;
};

export { ArgoFeatures, ArgoLayerStationInterval, ArgoLayerActiveStation };
