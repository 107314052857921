import styles from "./InvalidStations.module.scss";
import { t } from "i18next";

type Props = {
	numberOfInvalidStations: number;
};

const InvalidStations = ({ numberOfInvalidStations }: Props): JSX.Element => {
	return (
		<div className={`${styles["invalid-stations"]}`}>
			{t("frontpage:chart:invalidStations", { numberOfInvalidStations })}
		</div>
	);
};

export { InvalidStations };
export default InvalidStations;
