import Style from "ol/style/Style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Station } from "../../../../models/Argo/Station";
import { LatLon } from "../../../../models/LatLon";
import { floatStyle, floatMarker } from "./styles";
import { isEqual } from "date-fns";

interface StationsProps {
	/** The country the station's argo belongs to */
	country: string;
	/** The type the station's has */
	argoType: string;
	/** List of stations to render */
	stations: (Station & { pos: LatLon })[];
	/** Z index of the map layer */
	zIndex: number;
	/** The focused station */
	focusedStation: Station | null;
	/** The first station's timestamp */
	argoFirstStationTimeStamp: Date;
	/** Callback for when a station is clicked */
	onStationClick: (station: Station) => void;
}

const StationFeatures = ({
	country,
	argoType,
	stations,
	focusedStation,
	argoFirstStationTimeStamp,
	zIndex
}: StationsProps): Map<Feature<Point>, Station> => {
	return new Map(
		stations.map(station => {
			const { pos, number, wmo, timestamp } = station;
			const coord = [pos.lon, pos.lat];
			const point = new Point(coord);
			const feature = new Feature(point);
			let firstStation = false;
			if (isEqual(timestamp, argoFirstStationTimeStamp)) {
				// First station should have another color
				firstStation = true;
			}
			const styles: Style[] = [floatStyle(country, firstStation, argoType)];
			if (firstStation) {
				// First station z Index should be the highest
				styles[0].setZIndex(zIndex + 1);
			}
			if (focusedStation && wmo === focusedStation.wmo && number === focusedStation.number) {
				// If this is the focused station, then add an additional styling
				styles.push(floatMarker());
				// Set zIndex to be higher than firstStation
				styles[styles.length - 1].setZIndex(zIndex + 2);
			}

			// Set ID to find the feature later in onClick
			feature.setId(`${wmo}_${number}`);

			// XXX Style for some reason cannot be set in the feature constructor
			feature.setStyle(styles);

			return [feature, station];
		})
	);
};

export default StationFeatures;
export { StationFeatures };
