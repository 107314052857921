import { isValid, isBefore, isEqual } from "date-fns";
import styles from "../pages/Frontpage.module.scss";
import { useState } from "react";
import DateInput from "./DateInput";
import { useTranslation } from "react-i18next";

interface DateIntervalSelectorProps {
	onDateIntervalChange: (dateInterval: Interval) => void;
	initialDateInterval: Interval;
}
/**
 * Component for taking a date interval from the user. The component validates dates. Standard date displayed in the input-filed are start: todays date - 60 days, end: todays date.
 * @param onDateIntervalChange: Function executed when pressing the Filter-button in the component.
 * @returns JSX.Element representing a date interval selector.
 */
const DateIntervalSelector = ({
	onDateIntervalChange,
	initialDateInterval: { start, end }
}: DateIntervalSelectorProps): JSX.Element => {
	const [startDate, setStartDate] = useState(new Date(start));
	const [endDate, setEndDate] = useState(new Date(end));
	const datesNotValid = !(
		isValid(startDate) &&
		isValid(endDate) &&
		(isBefore(startDate, endDate) || isEqual(startDate, endDate))
	);
	const { t } = useTranslation();

	return (
		<div className={styles["date-interval-selector"]}>
			<h5>{t("frontpage:sidebar:filterTitle")}</h5>
			<div>
				<div>
					<DateInput value={startDate} onChange={setStartDate} max={endDate}></DateInput>
				</div>
				<div className={"input-group"}>
					<DateInput value={endDate} onChange={setEndDate} min={startDate}></DateInput>
					<button onClick={() => setEndDate(new Date())}>{t("frontpage:sidebar:today")}</button>
				</div>
			</div>
			<div>
				<button
					className="w-100"
					onClick={() => onDateIntervalChange({ start: startDate, end: endDate })}
					disabled={datesNotValid}
				>
					{t("frontpage:sidebar:filterButton")}
				</button>
			</div>
		</div>
	);
};

export default DateIntervalSelector;
export { DateIntervalSelector };
