import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { settings } from "../Settings";

import en from "./en";
import nb from "./nb";

void i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: settings.NODE_ENV === "development",
		lng: "en",
		interpolation: {
			escapeValue: false
		},
		resources: {
			en,
			nb
		}
	});
export default i18n;
