import { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import { DataPoint } from "../../models/Argo/DataPoint";
import { useTranslation } from "react-i18next";
import { ClimatologyMeanStDev, ClimatologyResponse } from "../../models/Backend/ClimatologyApi";

interface ChartProps {
	data: DataPoint[];
	shadowData: DataPoint[][];
	displayShadowData: boolean;
	displayClimatologyProfile: boolean;
	unit: string;
	height: number;
	meanAndStDevData?: ClimatologyResponse;
}

const stationToShadowPoint = (station: unknown[]): unknown => ({
	name: "",
	showInLegend: false,
	data: station,
	turboThreshold: 5000, // Graph does ont render if there are 1000 datapoints
	type: "spline",
	marker: {
		enabled: false,
		states: {
			hover: {
				enabled: false
			}
		}
	},
	tooltip: {
		pointFormat: "",
		pointFormatter: function () {
			return "";
		}
	},
	enableMouseTracking: false,
	zIndex: 0,
	color: "#6c757d",
	opacity: 0.4,
	lineWidth: 1
});

const toXY = (point: DataPoint) => ({
	x: point.depth,
	y: point.value
});

const hasStDev = (dataList: ClimatologyMeanStDev[]): boolean => {
	for (const data of dataList) {
		if (`${data.standardDeviation}` !== "NaN") {
			if ((data.standardDeviation as number) > 0) return true;
		}
	}
	return false;
};

const addMeanAndStDevArea = (chart: Highcharts.Chart, climatologyData: ClimatologyResponse): void => {
	chart.addSeries({
		name: "Mean",
		showInLegend: climatologyData.dataList.length > 0,
		type: "line",
		label: { format: climatologyData.variableName },
		data: climatologyData.dataList.map(d => [d.depth, d.mean]),
		color: "red",
		zIndex: 2,
		enableMouseTracking: false
	});
	chart.addSeries({
		name: "Standard Deviation",
		showInLegend: hasStDev(climatologyData.dataList),
		marker: { radius: 0 },
		type: "arearange",
		data: climatologyData.dataList.map(d => {
			let stdDev = null;
			if (d.standardDeviation !== "NaN") {
				stdDev = d.standardDeviation as number;
			}
			return [d.depth, stdDev ? d.mean - stdDev : null, stdDev ? d.mean + stdDev : null];
		}),
		color: "#CAEDCA",
		zIndex: 1,
		lineColor: "#42E642",
		enableMouseTracking: false
	});
};

const Chart = ({
	data,
	shadowData,
	displayShadowData,
	displayClimatologyProfile,
	unit,
	height,
	meanAndStDevData
}: ChartProps): JSX.Element => {
	// Reference to the element to render the chart into
	const chartElemRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	// Set up the map when the component has rendered, and whenever it rerenders
	useEffect(() => {
		// Don't do anything if there is no element to render the chart into
		if (chartElemRef.current === null) {
			return;
		}

		const dataSeries = data.map(toXY).sort((a, b) => a.x - b.x);
		const shadowDataSeries = shadowData.map(s => s.map(toXY).sort((a, b) => a.x - b.x));
		const series = [];
		// First object is current station
		const focusedStation = {
			name: "Profile",
			showInLegend: false,
			data: dataSeries,
			turboThreshold: 5000, // Graph does ont render if there are 1000 datapoints
			type: "spline",
			marker: {
				enabled: false,
				states: {
					hover: {
						enabled: true
					}
				}
			},
			tooltip: {
				pointFormat: "<b>dbar: {point.x}</b> <br/> " + `<b>${unit}: ` + "{point.y}</b>"
			},
			zIndex: 3,
			color: "#0d6efd",
			lineWidth: 3
		};
		series.push(focusedStation);

		if (shadowDataSeries.length > 0 && displayShadowData) {
			series.push(...shadowDataSeries.map(stationToShadowPoint));
		}

		const chartOptions = {
			chart: {
				animation: false,
				inverted: true,
				height: `${height}px`, // Weird stuff happens if metadata list is too long without setting height
				zoomType: "xy"
			},
			title: {
				text: "" // XXX If not set, highcharts gives default title
			},
			credits: {
				// text: "" // Credits to highcharts
			},
			// TODO: Not sure if this should be kept
			tooltip: {
				shared: true,
				useHTML: true,
				headerFormat: ""
				// enabled: false
			},
			xAxis: [
				{
					// tickInterval: // TODO: Some intervals should be set here or not?
					title: {
						text: t("frontpage:chart:depth")
					},
					labels: {
						format: "{value}"
					}
				}
			],
			yAxis: [
				{
					title: {
						text: `${t("frontpage:chart:values")} (${unit})`
					},
					startOnTick: true,
					// minPadding: 0.1, // Padding can be adjust to 0 to have "no empty space"
					// maxPadding: 0.1, // Padding can be adjust to 0 to have "no empty space"
					gridLineColor: "rgba(128, 128, 128, 0.1)" // Color of the minor, secondary grid lines.
				}
			],
			legend: {
				enabled: true
			},
			plotOptions: {
				series: {
					// pointPlacement: "between",
					animation: false
				}
			},
			series: series
		} as Highcharts.Options;

		highchartsMore(Highcharts);
		// Render the chart into the given DOM element
		const chart = Highcharts.chart(chartElemRef.current, chartOptions);

		if (displayClimatologyProfile && meanAndStDevData) {
			addMeanAndStDevArea(chart, meanAndStDevData);
		}

		return () => {
			// Clean up the chart, possibly triggered too much?
			chart.destroy();
		};
	}, [data, shadowData, displayShadowData, displayClimatologyProfile, t, unit, height, meanAndStDevData]);

	return <div ref={chartElemRef}></div>;
};

export default Chart;
export { Chart };
