import styles from "./Tree.module.scss";

interface LeafProps {
	/** The text to show on the item. */
	label: string;
	/** The level the item is on in the built tree-hierarchy */
	level: number;
	/** Indicates if the checkbox is checked or not*/
	isChecked: boolean;
	/** Function to run when the checkbox is checked/unchecked */
	onChange: (checked: boolean) => void;
	/** Icon should be rendered */
	iconUrl?: string;
	/** Hover over text translastion */
	tooltipLabel?: string;
}

/**
 * Creates a TreeLeaf for a tree. A TreeLeaf contains a checkbox and a label.
 * @param label Name of the TreeLeaf.
 * @param isChecked boolean indicating if the checkbox in the TreeLeaf is checked or not.
 * @param onChange function that will execute on a change event.
 * @param level Hierarchy-level the item is at, in the constructed tree.
 * @param tooltipLabel Label of tooltip
 * @returns a TreeLeaf-element.
 */
const Leaf = ({ label, isChecked, onChange, level, iconUrl, tooltipLabel }: LeafProps): JSX.Element => {
	return (
		<li className={`${styles["leaf"]} ${styles[`tree-level-${level}`]}`}>
			<label title={tooltipLabel}>
				<input type="checkbox" checked={isChecked} onChange={e => onChange(e.target.checked)} />
				<span>{label}</span>
				{iconUrl ? <img src={iconUrl} alt={""} /> : null}
			</label>
		</li>
	);
};

export default Leaf;
export { Leaf };
