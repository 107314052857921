import * as z from "zod";

// The settings in this file are set at build time (which includes in the dev environment) via environment variables.
// See https://vitejs.dev/guide/env-and-mode.html for more info on how env vars work

/** Schema for parsing buildtime settings */
const buildtimeSettingsSchema = z.object({
	NODE_ENV: z.string().optional(),
	PUBLIC_URL: z.string().optional(),
	NORARGO_BACKEND_API_URL: z.string().optional(),
	CLIMATOLOGY_URL: z.string().optional()
});

/** Settings which are set at build time */
export type BuildtimeSettings = z.infer<typeof buildtimeSettingsSchema>;

/** Settings which are set at build time */
const rawEnv = process.env;
const buildtimeSettings: BuildtimeSettings = buildtimeSettingsSchema.parse({
	NODE_ENV: rawEnv.NODE_ENV, // Set automatically by CRA depending on build environment
	PUBLIC_URL: rawEnv.PUBLIC_URL, // Set automatically by CRA during build
	NORARGO_BACKEND_API_URL: rawEnv.REACT_APP_NORARGO_BACKEND_API_URL,
	CLIMATOLOGY_URL: rawEnv.REACT_APP_CLIMATOLOGY_URL
});

export default buildtimeSettings;
export { buildtimeSettings };
