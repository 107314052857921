import OTileLayer from "ol/layer/Tile";
import TileSource from "ol/source/Tile";
import { useContext, useEffect, useMemo } from "react";
import MapContext from "../MapContext";

interface TileLayerProps {
	/** Source for the tiles */
	source: TileSource;
	/** Where in the layer stack this layer should be placed */
	zIndex: number;
}

const TileLayer = ({ source, zIndex }: TileLayerProps): null => {
	// Extract the map from the context
	const { map } = useContext(MapContext);

	// Store the layer between runs
	const layer = useMemo(() => new OTileLayer({ source }), [source]);

	// Set zIndex
	layer.setZIndex(zIndex);

	// Add it to the map
	useEffect(() => {
		map?.addLayer(layer);

		return () => {
			map?.removeLayer(layer);
		};
	}, [map, layer]);

	return null;
};

export default TileLayer;
export { TileLayer };
