import { Station } from "../../../../models/Argo/Station";
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import { floatStyle } from "./styles";
import { LatLon } from "../../../../models/LatLon";

interface PathProps {
	country: string;
	stations: (Station & { pos: LatLon })[];
	zIndex: number;
}

const PathFeature = ({ country, stations }: PathProps): Feature<LineString> => {
	// Build the vector source containing the path
	const coordinates = stations.map(({ pos }) => [pos.lon, pos.lat]);
	const line = new LineString(coordinates);
	const feature = new Feature(line);
	feature.setStyle(floatStyle(country));

	return feature;
};

export default PathFeature;
export { PathFeature };
