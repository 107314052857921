import * as yup from "yup";

/** Type of a country */
export type Country = string;

/** Validation schema for the CountryList type */
export const countrySchema = yup.string();

// Make sure the schema actually describes the type. This line will cause compilation errors if they do not match
countrySchema as yup.Schema<Country>;
